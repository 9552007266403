import { getDownloadURL, ref } from "firebase/storage";
import { storage } from "../firebase";

const fetchDrawing = async (drawingNumber) => {
    const dRef = ref(storage,`drawings/${drawingNumber}.pdf`);
    let url = "#";
    try{
        url=await getDownloadURL(dRef);
    }
    catch(e){
        url="#";
    }

    return url;
}

export default fetchDrawing;