import { collection, getDocs, orderBy, query, where } from "firebase/firestore";
import { db } from "../firebase";
/* eslint-disable camelcase */
const fetchWorker = async (worker, startDate, endDate) => {
  const dbRef = collection(db, "workers_data");
  const q = query(
    dbRef,
    where("worker", "==", worker),
    orderBy("date", "desc"),
    where("date", ">=", startDate),
    where("date", "<=", endDate)
  );
  const snapshot = await getDocs(q);
  const data = snapshot.docs.map((doc) => ({
    ...doc.data(),
    ...{
      "estimated time": (Math.round(Number(doc.data().estimated_time || 0) * 4) / 4).toFixed(2),
      "consumed time": (Math.round(Number(doc.data().consumed_time || 0) * 4) / 4).toFixed(2),
      date: new Date(doc.data().date.seconds * 1000).toLocaleDateString("en-UK"),
      "operator performance job": Number(doc.data().operator_performance_job || 0).toFixed(3),
    },
  }));
  return {
    data,
    performance:
      (data.reduce((n, { estimated_time }) => n + estimated_time, 0) /
        data.reduce((n, { consumed_time }) => n + consumed_time, 0)) *
      100,
  };
};

export default fetchWorker;
