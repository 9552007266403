function getDays(month) {
  const today = new Date(Date.now());
  const year = today.getFullYear();
  const days = new Date(year, month + 1, 0).getDate();
  let sat = 0;
  let sun = 0;
  for (let i = 1; i <= days; i += 1) {
    const day = new Date(year, month, i).getDay();
    if (day === 0) {
      sun += 1;
    }
    if (day === 6) {
      sat += 1;
    }
  }
  return {
    sun,
    sat,
    weekdays: days - (sun + sat),
  };
}

function getAvailableDays(month) {
  const today = new Date(Date.now());
  const year = today.getFullYear();
  const days = new Date(year, month + 1, 0).getDate();
  let sat = 0;
  let sun = 0;
  for (let i = today.getDate() + 1; i <= days; i += 1) {
    const day = new Date(year, month, i).getDay();
    if (day === 0) {
      sun += 1;
    }
    if (day === 6) {
      sat += 1;
    }
  }
  return {
    sun,
    sat,
    weekdays: days - today.getDate() - (sun + sat),
  };
}

function calcTotalHours(month) {
  const days = getDays(month);
  return days.sat * 4.75 + days.weekdays * 15.25;
}

function calcfromTodayTotalHours(month) {
  const days = getAvailableDays(month);
  return days.sat * 4.75 + days.weekdays * 15.25;
}

export default function getMonthlyTotalHours() {
  const firstMonth = new Date(Date.now()).getMonth();
  const secondMonth = new Date(Date.now()).getMonth() + 1;
  const thirdMonth = new Date(Date.now()).getMonth() + 2;

  return {
    1: calcTotalHours(firstMonth) - calcfromTodayTotalHours(firstMonth),
    2: calcTotalHours(secondMonth),
    3: calcTotalHours(thirdMonth),
    fromToday: calcfromTodayTotalHours(firstMonth),
  };
}

export function getTotalHoursPerMonth(month, year) {
  const today = new Date(Date.now());

  if (month === today.getMonth() && year === today.getFullYear()) {
    console.log(calcTotalHours(month) - calcfromTodayTotalHours(month));
    return calcTotalHours(month) - calcfromTodayTotalHours(month);
  }
  return calcTotalHours(month);
}
