import { sendPasswordResetEmail, signInWithEmailAndPassword, signOut } from "firebase/auth";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { auth } from "../firebase";
import useFetchData from "./useFetchData";

/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unused-vars */

export default function useAuth() {
  const [isAuth, setIsAuth] = useState(null);

  useEffect(() => {
    auth.onAuthStateChanged((authData) => {
      if (authData) {
        setIsAuth(true);
      } else {
        setIsAuth(false);
      }
    });
  }, []);

  return [isAuth];
}

export function useSignIn() {
  const [dispatchData, dispatchCompletedMOs] = useFetchData();
  const [logged, setLogged] = useState(false);
  const [error, setError] = useState(null);
  async function signIn(email, password) {
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        setLogged(true);
        dispatchData();
        dispatchCompletedMOs();
      })
      .catch((err) => {
        setError({
          errorCode: err.code,
          errorMessage: err.message,
        });
      });
  }

  return [signIn, logged, error];
}

export function useSignOut() {
  // const [isAuth] = useAuth();
  const navigate = useNavigate();

  async function signOutLocal() {
    await signOut(auth);
    navigate("/sign_in");
  }

  return signOutLocal;
}

export function useForgotPassword() {
  const [sent, setSent] = useState(false);
  const [notSent, setNotSent] = useState(false);
  async function resetPassword(email) {
    sendPasswordResetEmail(auth, email)
      .then((res) => {
        setSent(true);
        setInterval(setSent, 6000, false);
      })
      .catch((err) => {
        setNotSent(true);
        setInterval(setNotSent, 6000, false);
      });
  }

  return [resetPassword, sent, notSent];
}
