import { collection, getDocs } from "firebase/firestore";
import { db } from "../firebase";

export default async function fetchJobs(projectKey,partKey){
    const data = await getDocs(collection(db, 'projects',projectKey,'parts',partKey,'jobs')).then((querySnapshot) => {
        const partData = querySnapshot.docs.map((doc) => {
          const originalData = doc.data();
          originalData.key = doc.id
                try{
            originalData.createdAt=originalData.createdAt.seconds*1000;
            originalData.updatedAt=originalData.updatedAt.seconds*1000;
          }
          catch(e){
            originalData.createdAt=Date.now()
            originalData.updatedAt=Date.now()
          }
          return originalData;
        });
        return partData;
      });
    return data;
}