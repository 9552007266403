/* eslint-disable react/function-component-definition */
/* eslint-disable arrow-body-style */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/button-has-type */
/* eslint-disable no-unused-vars */
/* eslint-disable react/self-closing-comp */

import React from "react";

import PropTypes from "prop-types";

import "./lathe.css";
import MDButton from "components/MDButton";
import MDBox from "components/MDBox";
import { Tooltip, Typography, Stack } from "@mui/material";
import useGetMachinesPerformances from "hooks/useGetMachinesPerformances";

const Lathe = (props) => {
  const { machines, workers } = props;
  const sg = machines.filter((machine) => machine.department === "SG");
  const percs = useGetMachinesPerformances(sg);

  const data = {
    button1: sg.filter((machine) => machine.key === "sg3")[0],
    button2: sg.filter((machine) => machine.key === "sg4")[0],
    button3: sg.filter((machine) => machine.key === "sg5")[0],
    button4: sg.filter((machine) => machine.key === "sg6")[0],
    button5: sg.filter((machine) => machine.key === "sg7")[0],
    button6: sg.filter((machine) => machine.key === "sg8")[0],
    button7: sg.filter((machine) => machine.key === "sg9")[0],
    button8: sg.filter((machine) => machine.key === "sg10")[0],
    button9: sg.filter((machine) => machine.key === "sg11")[0],
    button10: sg.filter((machine) => machine.key === "sg12")[0],
    button11: sg.filter((machine) => machine.key === "sg1")[0],
    button: sg.filter((machine) => machine.key === "sg2")[0],
  };
  const getColor = (btn) => {
    if (btn.running) {
      return "success";
    }
    if (btn.partoff) {
      return "partoff";
    }
    if (btn.working) {
      return "info";
    }

    return "error";
  };

  const getMessage = (_button) => {
    let msg = "";
    try {
      const matches = workers.filter((w) => parseInt(w.id, 10) === parseInt(_button.worker, 10));
      msg = (
        <Stack>
          <Typography variant="body">Machine: {_button.name}</Typography>
          <Typography variant="body">Job: {_button.assigned_work}</Typography>
          <Typography variant="body">
            Operator:{" "}
            {_button.worker === "N/A" || !_button.worker ? _button.worker : matches[0].name}
          </Typography>
          <Typography variant="body">Utilization: {percs[_button.key] || 0}%</Typography>
        </Stack>
      );
    } catch (e) {
      msg = "Loading...";
    }
    return msg;
  };
  return (
    <div className={`lathe-container ${props.rootClassName} `}>
      <div className="lathe-container1">
        <Tooltip title={getMessage(data.button11)}>
          <MDBox mx={1}>
            <MDButton
              color={getColor(data.button11)}
              size="small"
              iconOnly
              className="lathe-button button"
            >
              {props.button11}
            </MDButton>
          </MDBox>
        </Tooltip>
        <Tooltip title={getMessage(data.button)}>
          <MDBox mx={1}>
            <MDButton
              color={getColor(data.button)}
              size="small"
              iconOnly
              className="lathe-button button"
            >
              {props.button}
            </MDButton>
          </MDBox>
        </Tooltip>
        <Tooltip title={getMessage(data.button1)}>
          <MDBox mx={1}>
            <MDButton
              color={getColor(data.button1)}
              size="small"
              iconOnly
              className="lathe-button01 button"
            >
              {props.button1}
            </MDButton>
          </MDBox>
        </Tooltip>
        <Tooltip title={getMessage(data.button2)}>
          <MDBox mx={1}>
            <MDButton
              color={getColor(data.button2)}
              size="small"
              iconOnly
              className="lathe-button02 button"
            >
              {props.button2}
            </MDButton>
          </MDBox>
        </Tooltip>
        <Tooltip title={getMessage(data.button3)}>
          <MDBox mx={1}>
            <MDButton
              color={getColor(data.button3)}
              size="small"
              iconOnly
              className="lathe-button03 button"
            >
              {props.button3}
            </MDButton>
          </MDBox>
        </Tooltip>
      </div>
      <div className="lathe-container2">
        <Tooltip title={getMessage(data.button4)}>
          <MDBox mx={2}>
            <MDButton
              color={getColor(data.button4)}
              size="small"
              iconOnly
              className="lathe-button04 button"
            >
              {props.button4}
            </MDButton>
          </MDBox>
        </Tooltip>
        <Tooltip title={getMessage(data.button5)}>
          <MDBox mx={2}>
            <MDButton
              color={getColor(data.button5)}
              size="small"
              iconOnly
              className="lathe-button05 button"
            >
              {props.button5}
            </MDButton>
          </MDBox>
        </Tooltip>
        <Tooltip title={getMessage(data.button6)}>
          <MDBox mx={2}>
            <MDButton
              color={getColor(data.button6)}
              size="small"
              iconOnly
              className="lathe-button06 button"
            >
              {props.button6}
            </MDButton>
          </MDBox>
        </Tooltip>
      </div>
      <div className="lathe-container3">
        <Tooltip title={getMessage(data.button7)}>
          <MDBox mx={3}>
            <MDButton
              color={getColor(data.button7)}
              size="small"
              iconOnly
              className="lathe-button07 button"
            >
              {props.button7}
            </MDButton>
          </MDBox>
        </Tooltip>
        <Tooltip title={getMessage(data.button8)}>
          <MDBox mx={1}>
            <MDButton
              color={getColor(data.button8)}
              size="small"
              iconOnly
              className="lathe-button08 button"
            >
              {props.button8}
            </MDButton>
          </MDBox>
        </Tooltip>
        <Tooltip title={getMessage(data.button9)}>
          <MDBox mx={1}>
            <MDButton
              color={getColor(data.button9)}
              size="small"
              iconOnly
              className="lathe-button09 button"
            >
              {props.button9}
            </MDButton>
          </MDBox>
        </Tooltip>
        <Tooltip title={getMessage(data.button10)}>
          <MDBox mx={1}>
            <MDButton
              color={getColor(data.button10)}
              size="small"
              iconOnly
              className="lathe-button10 button"
            >
              {props.button10}
            </MDButton>
          </MDBox>
        </Tooltip>
      </div>
    </div>
  );
};

Lathe.defaultProps = {
  button: "SG2",
  button1: "SG3",
  button2: "SG4",
  button3: "SG5",
  button4: "SG6",
  button5: "SG7",
  button6: "SG8",
  button7: "SG9",
  button8: "SG\n10",
  button9: "SG\n11",
  button10: "SG\n12",
  button11: "SG1",
  rootClassName: "",
};

Lathe.propTypes = {
  button: PropTypes.string,
  button1: PropTypes.string,
  button2: PropTypes.string,
  button3: PropTypes.string,
  button4: PropTypes.string,
  button5: PropTypes.string,
  button6: PropTypes.string,
  button7: PropTypes.string,
  button8: PropTypes.string,
  button9: PropTypes.string,
  button10: PropTypes.string,
  button11: PropTypes.string,
  rootClassName: PropTypes.string,
  machines: PropTypes.arrayOf(PropTypes.any).isRequired,
  workers: PropTypes.arrayOf(PropTypes.any).isRequired,
};

export default Lathe;
