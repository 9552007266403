/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components

import { Launch, Delete } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { useDeleteCustomer } from "hooks/useCustomers";

import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

export default function data(openDialog) {
  const customers = useSelector((state) => state.customers);
  const deleteCustomer = useDeleteCustomer();
  const navigate = useNavigate();

  const goTo = (path) => {
    navigate(path);
  };

  const handleDelete = async (customerKey) => {
    await deleteCustomer(customerKey);
  };

  return {
    columns: [
      { Header: "Customer Id", accessor: "cid", width: "20%", align: "left" },
      { Header: "Customer Name", accessor: "name", align: "left" },
      { Header: "Details", accessor: "details", align: "left" },
      { Header: "Customize", accessor: "customize", align: "center" },
    ],
    rows: customers.map((customer) => ({
      cid: customer.id,
      name: customer.name,
      details: (
        <div>
          <IconButton
            onClick={() => {
              goTo(`/projects?name=${customer.name}`);
            }}
          >
            <Launch />
          </IconButton>
        </div>
      ),
      customize: (
        <div>
          <IconButton
            onClick={() => {
              openDialog({
                k: customer.key,
                handleAction: handleDelete,
                title: "Are you sure to delete?",
                context: `You are going to delete ${customer.name || "N/A"}`,
              });
            }}
          >
            <Delete />
          </IconButton>
        </div>
      ),
    })),
  };
}
