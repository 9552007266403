/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-unused-vars */
import { useState } from "react";

// Material Dashboard 2 React components
import MDSnackbar from "components/MDSnackbar";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import VerticalBarChart from "examples/Charts/BarCharts/VerticalBarChart";
import PieChart from "examples/Charts/PieChart";

// data
import {verticalBarChartData,pieChartData} from "./data"


function Machine() {


  return (
    <DashboardLayout>
      <DashboardNavbar />
        <VerticalBarChart
            icon={{ color: "dark", component: "leaderboard" }}
            title="Bar chart"
            description="Sales related to age average"
            chart={verticalBarChartData}
        />
        <PieChart
            icon={{ color: "success", component: "donut_small" }}
            title="Machine Runtime"
            description="Total operated hours on monthly basis"
            chart={pieChartData}
            />
      <Footer />
    </DashboardLayout>
  );
}

export default Machine;
