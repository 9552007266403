/* eslint-disable react/function-component-definition */
/* eslint-disable arrow-body-style */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/button-has-type */
/* eslint-disable no-unused-vars */
/* eslint-disable react/self-closing-comp */

import React, { useEffect, useMemo, useState } from "react";

import MDBox from "components/MDBox";

import { useSelector, useDispatch } from "react-redux";

import workersPerformancesData from "layouts/dashboard/data/workersPerformancesData";
import workersEffectiveHoursData from "layouts/dashboard/data/workersEffectiveHoursData";
import { Box, Card, Grid, Paper, Stack, Typography } from "@mui/material";
import PageLayout from "examples/LayoutContainers/PageLayout";
import useGetMachinesPerformances from "hooks/useGetMachinesPerformances";
import MDAvatar from "components/MDAvatar";
import { getPPs } from "redux/slices/workersSlice";
import { getMachines } from "redux/slices/machinesSlice";

import "./index.css";
import CNCM from "layouts/machines/components/c-n-c-m";
import PG from "layouts/machines/components/p-g";
import EDM from "layouts/machines/components/e-d-m";
import SG from "layouts/machines/components/s-g";
import Lathe from "layouts/machines/components/lathe";
import Mill from "layouts/machines/components/mill";
import Press from "layouts/machines/components/press";
import { CCarousel, CCarouselItem } from "@coreui/react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

import { Pagination, Autoplay, Virtual } from "swiper";

// Import Swiper styles
import "swiper/swiper.min.css";
import "swiper/swiper-bundle.min.css";

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
// import { Carousel } from "react-responsive-carousel";

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import WorkshopLayoutDisplay from "layouts/machines_display";
import ReactPlayer from "react-player/lazy";

const WorkshopLayoutDisplay2 = (props) => {
  const [footer, setFooter] = useState("");
  const machines = useSelector((state) => state.machines);
  const workers = useSelector((state) => state.workers);

  const workingMachines = machines; // .filter((machine) => machine.running);
  const percs = useGetMachinesPerformances(workingMachines);

  const dispatch = useDispatch();

  useMemo(() => {
    dispatch(getPPs(workers));
  }, [machines]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setFooter("Refreshing...");
      dispatch(getMachines());
      setFooter(`Updated at ${new Date(Date.now()).toTimeString()}`);
    }, 5 * 60000);
    return () => clearTimeout(timer);
  });
  const settings = {
    dots: false,
    infinite: true,
    speed: 4000,
    autoplay: true,
    pauseOnHover: false,
  };
  const responsiveSub = (count) => ({
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: count,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  });
  const responsiveMain = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  const getDetails = (dep, count, centerMode) => (
    <Carousel
      responsive={responsiveSub(count)}
      key={dep}
      autoPlay
      infinite
      arrows={false}
      autoPlaySpeed={4000}
      centerMode
    >
      {workingMachines
        .filter((machine) => machine.department === dep)
        .map((machine) => (
          <Box
            key={machine.name}
            sx={{
              display: "flex",
              flexWrap: "wrap",
              "& > :not(style)": {
                m: 1,
                width: 240,
                height: 200,
              },
            }}
          >
            <Paper
              elevation={5}
              sx={{
                color: "#ffffff",
                background: () => {
                  let color = "#1A73E8";
                  if (machine.running) {
                    color = "#4CAF50";
                  }
                  if (!machine.working) {
                    color = "#F44335";
                  }
                  if (machine.partoff) {
                    color = "#F4D03F";
                  }
                  return color;
                },
                textAlign: "center",
              }}
            >
              {" "}
              <MDAvatar
                size="xl"
                sx={{ m: "auto" }}
                src={
                  Number(machine.worker)
                    ? workers.filter((worker) => worker.id === machine.worker)[0].pp
                    : "N/A"
                }
              />
              <Stack>
                <Typography variant="body1" fontWeight={700}>
                  {machine.name} : {machine.assigned_work}
                </Typography>
                <Typography variant="body1" fontWeight={700}>
                  {Number(machine.worker)
                    ? workers.filter((worker) => worker.id === machine.worker)[0].name.split(" ")[0]
                    : "N/A"}
                </Typography>
                <Typography variant="body1" fontWeight={700}>
                  {percs[machine.key]}%
                </Typography>
              </Stack>
            </Paper>
          </Box>
        ))}
    </Carousel>
  );
  const getDetailsStack = (dep, count, centerMode) => (
    <Stack direction="row" justifyContent="center">
      {workingMachines
        .filter((machine) => machine.department === dep)
        .map((machine) => (
          <Box
            key={machine.name}
            sx={{
              display: "flex",
              flexWrap: "wrap",
              "& > :not(style)": {
                m: 1,
                width: 240,
                height: 200,
              },
            }}
          >
            <Paper
              elevation={5}
              sx={{
                color: "#ffffff",
                background: () => {
                  let color = "#1A73E8";
                  if (machine.running) {
                    color = "#4CAF50";
                  }
                  if (!machine.working) {
                    color = "#F44335";
                  }
                  if (machine.partoff) {
                    color = "#F4D03F";
                  }
                  return color;
                },
                textAlign: "center",
              }}
            >
              {" "}
              <MDAvatar
                size="xl"
                sx={{ m: "auto" }}
                src={
                  Number(machine.worker)
                    ? workers.filter((worker) => worker.id === machine.worker)[0].pp
                    : "N/A"
                }
              />
              <Stack>
                <Typography variant="body1" fontWeight={700}>
                  {machine.name} : {machine.assigned_work}
                </Typography>
                <Typography variant="body1" fontWeight={700}>
                  {Number(machine.worker)
                    ? workers.filter((worker) => worker.id === machine.worker)[0].name.split(" ")[0]
                    : "N/A"}
                </Typography>
                <Typography variant="body1" fontWeight={700}>
                  {percs[machine.key]}%
                </Typography>
              </Stack>
            </Paper>
          </Box>
        ))}
    </Stack>
  );

  return (
    <PageLayout>
      <Carousel
        responsive={responsiveMain}
        key="main"
        autoPlay
        infinite
        arrows={false}
        autoPlaySpeed={16000}
      >
        <Box key={0}>
          {getDetails("SG", 5, false)}
          {getDetails("PG", 5, false)}
          {getDetailsStack("WC")}
          {getDetailsStack("EDM")}
          {getDetailsStack("CNC")}
          <Typography>{footer}</Typography>
        </Box>
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
          style={{ minHeight: "100vh" }}
          key={1}
        >
          <Grid item xs={3}>
            <WorkshopLayoutDisplay
              machines={machines}
              workers={workers}
              workingMachines={workingMachines}
              footer={footer}
              percs={percs}
            />
          </Grid>
        </Grid>
        <ReactPlayer
          url="https://www.youtube.com/watch?v=YAOZfx3Awq0"
          loop
          muted
          playing
          controls={false}
          height="100vh"
          width="100vw"
        />
      </Carousel>
    </PageLayout>
  );
};

export default WorkshopLayoutDisplay2;
