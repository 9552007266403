import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getDocs, collection, query, where } from "firebase/firestore";
import { db } from "../../firebase";

export const workersPerformancesSlice = createSlice({
  name: "workersPerformances",
  initialState: [],
  reducers: {},
  /* eslint-disable no-use-before-define */
  /* eslint-disable no-unused-vars */
  /* eslint-disable no-param-reassign */
  /* eslint-disable prefer-destructuring */
  /* eslint-disable camelcase */
  extraReducers(builder) {
    builder.addCase(getWorkersPerformances.fulfilled, (state, action) => {
      localStorage.setItem(
        "worker_performances",
        new Date(Date.now()).toTimeString().split(" ")[0]
      );
      const data = action.payload.data;
      return data;
    });
  },
});

export const getWorkersPerformances = createAsyncThunk(
  "workers/getWorkersPerformances",
  async () => {
    const today = new Date(Date.now());
    const thisMonth = today.getMonth();
    const thisYear = today.getFullYear();
    const dataMonth = thisMonth === 0 ? 11 : thisMonth - 1;
    const dataYear = thisMonth === 0 ? thisYear - 1 : thisYear;
    const data = await getDocs(
      query(collection(db, "workers_data"), where("date", ">=", new Date(dataYear, dataMonth, 1)))
    ).then((querySnapshot) => {
      const newData = querySnapshot.docs.map((doc) => {
        const originalData = doc.data();
        originalData.key = doc.id;
        originalData.date = originalData.date.seconds * 1000;
        try {
          originalData.createdAt = originalData.createdAt.seconds * 1000;
          originalData.updatedAt = originalData.updatedAt.seconds * 1000;
        } catch (e) {
          originalData.createdAt = Date.now();
          originalData.updatedAt = Date.now();
        }

        return { ...originalData };
      });
      return newData;
    });
    return { data };
  }
);

export default workersPerformancesSlice.reducer;
