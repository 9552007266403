const { useSelector } = require("react-redux");

const useGetWorkersPerformances = (workers) => {
  const perc = {};
  const workersPerformances = useSelector((state) => state.workersPerformances).filter(
    (percentage) => {
      const today = new Date(Date.now());
      const percDate = new Date(percentage.date);
      return (
        today.getFullYear() === percDate.getFullYear() && today.getMonth() === percDate.getMonth()
      );
    }
  );

  workers.forEach((worker) => {
    const wPerc = {
      et: 0,
      ct: 0,
    };
    const selectedWorker = workersPerformances.filter((_worker) => _worker.worker === worker.id);
    selectedWorker.forEach((data) => {
      wPerc.et += data.estimated_time;
      wPerc.ct += data.consumed_time;
    });
    if (worker.id === "107") {
      console.log(selectedWorker);
    }
    perc[worker.id] = ((wPerc.et / wPerc.ct) * 100 || 0).toFixed(2);
  });
  return perc;
};

export default useGetWorkersPerformances;
