const { useSelector } = require("react-redux");

const useGetWorkersEffectiveHours = (workers) => {
  const perc = {};
  const workersPerformances = useSelector((state) => state.workersPerformances);

  workers.forEach((worker) => {
    const thisMonth = new Date(Date.now()).getMonth()
    let efH = 0;
    const selectedWorker = workersPerformances.filter((_worker) => _worker.worker === worker.id && new Date(_worker.date).getMonth()===thisMonth);
    selectedWorker.forEach((data) => {
      efH += data.consumed_time;
    });
    perc[worker.id] = efH;
  });
  return perc;
};

export default useGetWorkersEffectiveHours;
