/* eslint-disable no-unused-vars */
import { useSelector } from "react-redux";
import getMonthlyTotalHours from "utils/getMonthlyTotalHours";

export default function useGetAvailableHours() {
  /**
   * Redux related data
   */
  const machines = useSelector((state) => state.machines);
  const machinePerformances = useSelector((state) => state.machinesPerformances);
  const departments = Array.from(new Set(machines.map((machine) => machine.department)));
  const mosObj = useSelector((state) => state.mos);
  const mos = [];
  Object.keys(mosObj).forEach((key) => {
    mos.push(mosObj[key]);
  });

  /**
   * Other variables, constants and operations
   */
  const firstMonth = new Date(Date.now()).getMonth();
  const secondMonth = new Date(Date.now()).getMonth() + 1;
  const thirdMonth = new Date(Date.now()).getMonth() + 2;
  const monthlyTotalHours = getMonthlyTotalHours();
  const monthlyReservedHours = {};
  const count = {};

  departments.forEach((dep) => {
    count[dep] = machines.filter(
      (machine) => machine.department === dep && machine.working && !machine.partoff
    ).length;
  });
  for (let i = 1; i < 4; i += 1) {
    const reservedHours = {};
    departments.forEach((dep) => {
      reservedHours[dep] = 0;
    });
    monthlyReservedHours[i] = reservedHours;
    monthlyReservedHours.fromToday = reservedHours;
  }

  Object.keys(monthlyTotalHours).forEach((month) => {
    const machineCount = {};
    Object.keys(count).forEach((key) => {
      machineCount[key] = count[key] * monthlyTotalHours[month];
    });
    monthlyTotalHours[month] = machineCount;
  });

  mos.forEach((mo) => {
    const today = new Date(Date.now()).getDate();
    const thisYear = new Date(Date.now()).getFullYear();
    const month = new Date(mo.ed).getMonth();
    const date = new Date(mo.ed).getDate();
    const year = new Date(mo.ed).getFullYear();

    const { jobs } = mo;
    if (month === firstMonth && year === thisYear) {
      if (date >= today && mo.status !== "Completed") {
        jobs.forEach((job) => {
          monthlyReservedHours.fromToday[job.key] += Number(job.estimated_time);
        });
      }
    }
    if (month === secondMonth && year === thisYear && mo.status !== "Completed") {
      jobs.forEach((job) => {
        monthlyReservedHours[2][job.key] += Number(job.estimated_time);
      });
    }
    if (month === thirdMonth && year === thisYear && mo.status !== "Completed") {
      jobs.forEach((job) => {
        monthlyReservedHours[3][job.key] += Number(job.estimated_time);
      });
    }
  });

  machines.forEach((machine) => {
    const thisYear = new Date(Date.now()).getFullYear();
    const thisMonth = new Date(Date.now()).getMonth();
    const selectedMachine = machinePerformances.filter(
      (_machine) =>
        _machine.machine === machine.key &&
        new Date(_machine.date).getFullYear() === thisYear &&
        new Date(_machine.date).getMonth() === thisMonth
    );

    selectedMachine.forEach((data) => {
      monthlyReservedHours[1][machine.department] += Number(data.consumed_time);
    });
    if (monthlyReservedHours[1][machine.department] > monthlyTotalHours[1][machine.department]) {
      monthlyReservedHours[1][machine.department] = monthlyTotalHours[1][machine.department];
    }
  });

  return {
    total: monthlyTotalHours,
    reserved: monthlyReservedHours,
  };
}
