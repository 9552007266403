import { useSelector } from "react-redux";

export default function upcomingDeliveryData() {
  const upcoming = [];
  const projects = useSelector((state) => state.projects);
  const now = Date.now();
  const lowerMargin = now - 5 * 24 * 60 * 60 * 1000;
  const upperMargin = now + 5 * 24 * 60 * 60 * 1000;

  projects.forEach((project) => {
    if (
      project.estimated_delivery > lowerMargin &&
      project.estimated_delivery < upperMargin &&
      !project.completed
    ) {
      upcoming.push({
        ...project,
        ...{
          left: Math.round((project.estimated_delivery - now) / (1000 * 60 * 60 * 24)),
        },
      });
    }
  });
  return upcoming;
}
