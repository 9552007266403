/* eslint-disable react/function-component-definition */
/* eslint-disable arrow-body-style */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/button-has-type */
/* eslint-disable no-unused-vars */
/* eslint-disable react/self-closing-comp */

import React from "react";

import PropTypes from "prop-types";

import "./mill.css";
import MDButton from "components/MDButton";
import MDBox from "components/MDBox";
import { Stack, Tooltip, Typography } from "@mui/material";
import useGetMachinesPerformances from "hooks/useGetMachinesPerformances";

const Mill = (props) => {
  const { machines, workers } = props;
  const mill = machines.filter((machine) => machine.department === "CNC");
  const percs = useGetMachinesPerformances(mill);

  const data = {
    mill1: mill.filter((machine) => machine.key === "mill1")[0],
    mill2: mill.filter((machine) => machine.key === "mill2")[0],
    lathe1: mill.filter((machine) => machine.key === "lathe1")[0],
  };
  const getColor = (btn) => {
    if (btn.running) {
      return "success";
    }
    if (btn.partoff) {
      return "partoff";
    }
    if (btn.working) {
      return "info";
    }

    return "error";
  };

  const getMessage = (_button) => {
    let msg = "";
    try {
      const matches = workers.filter((w) => parseInt(w.id, 10) === parseInt(_button.worker, 10));
      msg = (
        <Stack>
          <Typography variant="body">Machine: {_button.name}</Typography>
          <Typography variant="body">Job: {_button.assigned_work}</Typography>
          <Typography variant="body">
            Operator:{" "}
            {_button.worker === "N/A" || !_button.worker ? _button.worker : matches[0].name}
          </Typography>
          <Typography variant="body">Utilization: {percs[_button.key] || 0}%</Typography>
        </Stack>
      );
    } catch (e) {
      msg = "Loading...";
    }
    return msg;
  };
  return (
    <div className={`mill-container ${props.rootClassName} `}>
      <Tooltip title={getMessage(data.mill1)}>
        <MDBox my={0.5}>
          <MDButton color={getColor(data.mill1)} size="small" className="mill-button button">
            {props.mill1}
          </MDButton>
        </MDBox>
      </Tooltip>
      <Tooltip title={getMessage(data.mill2)}>
        <MDBox my={0.5}>
          <MDButton color={getColor(data.mill2)} size="small" className="mill-button1 button">
            {props.mill2}
          </MDButton>
        </MDBox>
      </Tooltip>
      <Tooltip title={getMessage(data.lathe1)}>
        <MDBox my={0.5}>
          <MDButton color={getColor(data.lathe1)} size="small" className="button">
            {props.lathe1}
          </MDButton>
        </MDBox>
      </Tooltip>
    </div>
  );
};

Mill.defaultProps = {
  mill2: "MILLING02",
  lathe1: "LATHE01",
  mill1: "MILLING01",
  rootClassName: "",
};

Mill.propTypes = {
  mill2: PropTypes.string,
  lathe1: PropTypes.string,
  mill1: PropTypes.string,
  rootClassName: PropTypes.string,
  machines: PropTypes.arrayOf(PropTypes.any).isRequired,
  workers: PropTypes.arrayOf(PropTypes.any).isRequired,
};

export default Mill;
