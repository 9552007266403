import { doc, setDoc, deleteDoc } from "firebase/firestore";
import { useDispatch } from "react-redux";
import { getWorkers } from "redux/slices/workersSlice";
import { db } from "../firebase";

export const useAddNewWorker = () => {
  const dispatch = useDispatch();

  async function addNewUser(data) {
    if (data.name !== "" && data.id !== 0) {
      const dbRef = doc(db, "workers", data.id);
      const document = await setDoc(dbRef, {
        ...data,
        ...{ createdAt: new Date(Date.now()), updatedAt: new Date(Date.now()), assigned_work: [] },
      });
      dispatch(getWorkers());
      return document;
    }
    throw new Error("invalid data");
  }

  return addNewUser;
};

export const useDeleteUser = () => {
  const dispatch = useDispatch();

  async function deleteUser(key) {
    const docRef = doc(db, "workers", key);
    await deleteDoc(docRef);
    dispatch(getWorkers());
  }

  return deleteUser;
};
