/* eslint-disable react/function-component-definition */
/* eslint-disable arrow-body-style */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/button-has-type */

import React from "react";

import PropTypes from "prop-types";

import "./c-n-c-m.css";
import MDButton from "components/MDButton";
import useGetMachinesPerformances from "hooks/useGetMachinesPerformances";
import { Stack, Tooltip, Typography } from "@mui/material";
import MDBox from "components/MDBox";

const CNCM = (props) => {
  const { machines, workers } = props;
  const cnc = machines.filter((machine) => machine.department === "CNC");
  const percs = useGetMachinesPerformances(cnc);

  const data = {
    button: cnc.filter((machine) => machine.key === "cnc1")[0],
  };
  const getColor = (btn) => {
    if (btn.running) {
      return "success";
    }
    if (btn.partoff) {
      return "partoff";
    }
    if (btn.working) {
      return "info";
    }

    return "error";
  };

  const getMessage = (_button) => {
    let msg = "";
    try {
      const matches = workers.filter((w) => parseInt(w.id, 10) === parseInt(_button.worker, 10));
      msg = (
        <Stack>
          <Typography variant="body">Machine: {_button.name}</Typography>
          <Typography variant="body">Job: {_button.assigned_work}</Typography>
          <Typography variant="body">
            Operator:{" "}
            {_button.worker === "N/A" || !_button.worker ? _button.worker : matches[0].name}
          </Typography>
          <Typography variant="body">Utilization: {percs[_button.key] || 0}%</Typography>
        </Stack>
      );
    } catch (e) {
      msg = "Loading...";
    }
    return msg;
  };

  return (
    <div className={`c-n-c-m-container ${props.rootClassName} `}>
      <Tooltip title={getMessage(data.button)}>
        <MDBox mx={1}>
          <MDButton color={getColor(data.button)} size="small" className="c-n-c-m-button button">
            {props.button}
          </MDButton>
        </MDBox>
      </Tooltip>
    </div>
  );
};

CNCM.defaultProps = {
  button: "CNC Milling",
  rootClassName: "",
};

CNCM.propTypes = {
  button: PropTypes.string,
  rootClassName: PropTypes.string,
  machines: PropTypes.arrayOf(PropTypes.any).isRequired,
  workers: PropTypes.arrayOf(PropTypes.any).isRequired,
};

export default CNCM;
