/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-curly-brace-presence */

// Material Dashboard 2 React example components
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { Document, Page } from "@react-pdf/renderer";
import Header from "./components/Header";
import ProjectData from "./components/ProjectData";
import EstimatedTimes from "./components/EstimatedTimes";

function MOView({
  partKey,
  mo,
  customer,
  ipo,
  received,
  cpo,
  item,
  delivery,
  quantity,
  type,
  material,
  jobs,
}) {
  const jobData = {};

  jobs.forEach((element) => {
    jobData[element.process] = parseFloat(element.estimated_time);
  });

  return (
    <Document>
      <Page size="A4">
        <Header partKey={partKey} />
        <ProjectData
          mo={mo}
          customer={customer}
          ipo={ipo}
          received={received}
          cpo={cpo}
          item={item}
          delivery={delivery}
          quantity={quantity}
          type={type}
          material={material}
        />
        <EstimatedTimes
          times={{
            sg: jobData.SG,
            pg: jobData.PG,
            edm: jobData.EDM,
            wc: jobData.WC,
            cnc: jobData.CNC,
            man: jobData["MANUAL MILL"],
            lathe: jobData.LATHE,
          }}
        />
      </Page>
    </Document>
  );
}

MOView.propTypes = {
  partKey: PropTypes.string.isRequired,
  mo: PropTypes.string.isRequired,
  customer: PropTypes.string.isRequired,
  ipo: PropTypes.string.isRequired,
  received: PropTypes.string.isRequired,
  cpo: PropTypes.string.isRequired,
  item: PropTypes.string.isRequired,
  delivery: PropTypes.string.isRequired,
  quantity: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
  material: PropTypes.string.isRequired,
  jobs: PropTypes.arrayOf(PropTypes.any).isRequired,
};

export default MOView;
