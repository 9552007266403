/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components

import { ArrowCircleRight, Delete, Save } from "@mui/icons-material";
import { Grid, IconButton, Stack } from "@mui/material";
import MDDropzone from "components/MDDropzone";
import MDInput from "components/MDInput";
import { useState, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";

import { useAddNewPart, useDeletePart } from "hooks/useParts";
import uploadDrawing from "utils/uploadDrawing";
import { getParts } from "redux/slices/partsSlice";
import { useNavigate } from "react-router-dom";
import { PDFDownloadLink } from "@react-pdf/renderer";
import MOView from "docs/pdf/MO/MOView";
import MDButton from "components/MDButton";
import fetchJobs from "utils/fetchJobs";
import fetchDrawing from "utils/fetchDrawing";
import CircularProgressWithLabel from "items/CircularProgressWithLabel";
import MDBox from "components/MDBox";
import Select from "react-select";

/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */

export default function data(editable, openDialog) {
  const mosObj = useSelector((state) => state.mos);
  const mos = [];
  Object.keys(mosObj).forEach((key) => {
    mos.push(mosObj[key]);
  });
  const [mO, setMO] = useState("");
  const [itemName, setItemName] = useState("");
  const [quantity, setQuantity] = useState(1);
  const [material, setMaterial] = useState("");
  const [eT, setET] = useState(0);
  const [status, setStatus] = useState("Not Started");
  const [generated, setGenerated] = useState({});
  const [pdfData, setPdfdata] = useState({});
  const pKey = window.location.pathname.split("/")[2];
  const parts = useSelector((state) => state.parts);
  const [drawings, setDrawings] = useState({});
  const [progress, setProgress] = useState();
  const [filter, setFilter] = useState("ALL");
  const [type, setType] = useState({
    label: "pc",
    value: "pc",
  });
  const [filteredParts, setFilteredParts] = useState([]);
  const projects = useSelector((state) => state.projects);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const addNewPart = useAddNewPart();
  const deletePart = useDeletePart();

  useMemo(() => {
    dispatch(getParts(pKey));
    Promise.all(
      parts.map(async (part) => {
        const drawing = await fetchDrawing(part.item_name);
        return { [part.item_name]: drawing };
      })
    ).then((links) => {
      let drawingsTemp = {};
      links.forEach((drawing) => {
        drawingsTemp = { ...drawingsTemp, ...drawing };
      });
      setDrawings(drawingsTemp);
    });

    Promise.all(
      parts.map(async (part) => {
        const procData = {
          status: {},
        };
        const { jobs } = mos.filter((mo) => mo.key === part.key)[0] ?? { jobs: [] };
        Object.keys(jobs).forEach((key) => {
          procData[jobs[key].process] = (jobs[key].progress || []).reduce(
            (n, { percentage }) => n + percentage,
            0
          );
          procData.status[jobs[key].process] = jobs[key].status;
        });
        return { [part.key]: procData };
      })
    ).then((percs) => {
      let temp = {};
      percs.forEach((perc) => {
        temp = { ...temp, ...perc };
      });
      setProgress(temp);
    });
  }, [parts.length]);

  useMemo(() => {
    if (progress) {
      const fm = parts.filter((part) => progress[part.key][filter]);
      setFilteredParts(fm);
      if (filter === "ALL") {
        setFilteredParts(parts);
      }
    }
  }, [filter, progress]);

  const handleFilter = (f) => {
    setFilter(f);
  };

  const clearSelection = () => {
    setMO("");
    setQuantity(1);
    setMaterial("");
    setET(0);
    setStatus("Not Started");
    setItemName("");
  };

  const handleSaving = async () => {
    await addNewPart(pKey, {
      mo: mO,
      item_name: itemName,
      quantity,
      material,
      estimated_time: eT,
      status,
      type: type.value,
    });
    clearSelection();
  };

  const handleEnter = async (event) => {
    if (event.key === "Enter") {
      await addNewPart(pKey, {
        mo: mO,
        item_name: itemName,
        quantity,
        material,
        estimated_time: eT,
        status,
        type: type.value,
      });
      clearSelection();
    }
  };

  const handleGoTo = (path) => {
    navigate(path);
  };

  const handleMOChange = (event) => {
    setMO(event.target.value);
  };
  const handleItemNameChange = (event) => {
    setItemName(event.target.value);
  };
  const handleQuantityChange = (event) => {
    setQuantity(event.target.value);
  };
  const handleMaterialChange = (event) => {
    setMaterial(event.target.value);
  };

  const handleGeneration = async (projectKey, partKey) => {
    const matches = projects.filter((prj) => prj.key === projectKey);
    const project = matches[0];
    const jobs = await fetchJobs(projectKey, partKey);
    setPdfdata({ ...project, ...{ jobs } });
    setGenerated({ [partKey]: true });
  };

  const handleLoadDrawing = async (drawingNumber) => {
    const drawing = await fetchDrawing(drawingNumber);
    setDrawings({ ...drawings, ...{ [drawingNumber]: drawing } });
  };

  const handleDelete = async (projectKey, partKey) => {
    await deletePart(projectKey, partKey);
  };

  const handleTypeChange = (event) => {
    setType(event);
  };

  const typeOptions = [
    {
      label: "pc",
      value: "pc",
    },
    {
      label: "set",
      value: "set",
    },
  ];

  return {
    columns: [
      { Header: "MO Number", accessor: "mo", align: "left" },
      { Header: "Item Name", accessor: "name", align: "left" },
      { Header: "Quantity", accessor: "quantity", align: "left" },
      { Header: "Material", accessor: "material", align: "left" },
      { Header: "Estimated Time", accessor: "et", align: "left" },
      { Header: "Status", accessor: "status", align: "left" },
      { Header: "Drawing", accessor: "drawing", align: "center" },
      { Header: "Customize", accessor: "customize", align: "center" },
    ],
    rows: [
      editable
        ? {
            mo: (
              <MDBox width={120}>
                <MDInput
                  value={mO}
                  onChange={handleMOChange}
                  onKeyDown={(event) => handleEnter(event)}
                />
              </MDBox>
            ),
            name: (
              <MDBox width={150}>
                <MDInput
                  value={itemName}
                  onChange={handleItemNameChange}
                  onKeyDown={(event) => handleEnter(event)}
                />{" "}
              </MDBox>
            ),
            quantity: (
              <MDBox width={200}>
                <Stack direction="row" spacing={2}>
                  <MDInput
                    key={0}
                    type="number"
                    value={quantity}
                    onChange={handleQuantityChange}
                    onKeyDown={(event) => handleEnter(event)}
                  />
                  <Select
                    key={1}
                    value={type}
                    placeholder="Type"
                    options={typeOptions}
                    onChange={handleTypeChange}
                    styles={{
                      menu: () => ({
                        background: "white",
                      }),
                      container: (provided) => ({
                        ...provided,
                        width: 400,
                      }),
                    }}
                  />
                </Stack>
              </MDBox>
            ),
            material: (
              <MDBox width={100}>
                <MDInput
                  value={material}
                  onChange={handleMaterialChange}
                  onKeyDown={(event) => handleEnter(event)}
                />
              </MDBox>
            ),
            et: eT,
            drawing: (
              <MDDropzone
                options={{
                  url: async (files) => {
                    const url = await uploadDrawing(files[0], itemName);
                    return url;
                  },
                  maxFiles: 1,
                  acceptedFiles: "application/pdf",
                  addRemoveLinks: true,
                }}
              />
            ),
            customize: (
              <IconButton onClick={handleSaving}>
                <Save />
              </IconButton>
            ),
          }
        : {},
      ...filteredParts.map((part) => ({
        mo: part.mo,
        name: part.item_name,
        quantity: part.quantity,
        material: part.material,
        et: mos
          .filter((mo) => mo.key === part.mo)[0]
          ?.jobs.reduce((n, { estimated_time }) => n + Number(estimated_time), 0),

        status: !progress[part.key] ? (
          <Grid container spacing={0.2}>
            <Grid item key="sg">
              <CircularProgressWithLabel variant="indeterminate" color="info" value={0} />
            </Grid>
            <Grid item key="pg">
              <CircularProgressWithLabel variant="indeterminate" color="warning" value={0} />
            </Grid>
            <Grid item key="edm">
              <CircularProgressWithLabel variant="indeterminate" color="primary" value={0} />
            </Grid>
            <Grid item key="wc">
              <CircularProgressWithLabel variant="indeterminate" color="success" value={0} />
            </Grid>
            <Grid item key="cnc">
              <CircularProgressWithLabel variant="indeterminate" color="secondary" value={0} />
            </Grid>
            <Grid item key="mm">
              <CircularProgressWithLabel variant="indeterminate" color="error" value={0} />
            </Grid>
            <Grid item key="lathe">
              <CircularProgressWithLabel variant="indeterminate" color="error" value={0} />
            </Grid>
          </Grid>
        ) : (
          <Grid container spacing={0.2}>
            <Grid item key="sg">
              {progress[part.key].SG >= 0 ? (
                <CircularProgressWithLabel
                  variant={
                    progress[part.key].status.SG === "Started" ? "indeterminate" : "determinate"
                  }
                  color="info"
                  value={progress[part.key].SG ? progress[part.key].SG : 0}
                />
              ) : (
                <div />
              )}
            </Grid>
            <Grid item key="pg">
              {progress[part.key].PG >= 0 ? (
                <CircularProgressWithLabel
                  variant={
                    progress[part.key].status.PG === "Started" ? "indeterminate" : "determinate"
                  }
                  color="warning"
                  value={progress[part.key].PG ? progress[part.key].PG : 0}
                />
              ) : (
                <div />
              )}
            </Grid>
            <Grid item key="edm">
              {progress[part.key].EDM >= 0 ? (
                <CircularProgressWithLabel
                  variant={
                    progress[part.key].status.EDM === "Started" ? "indeterminate" : "determinate"
                  }
                  color="primary"
                  value={progress[part.key].EDM ? progress[part.key].EDM : 0}
                />
              ) : (
                <div />
              )}
            </Grid>
            <Grid item key="wc">
              {progress[part.key].WC >= 0 ? (
                <CircularProgressWithLabel
                  variant={
                    progress[part.key].status.WC === "Started" ? "indeterminate" : "determinate"
                  }
                  color="success"
                  value={progress[part.key].WC ? progress[part.key].WC : 0}
                />
              ) : (
                <div />
              )}
            </Grid>
            <Grid item key="cnc">
              {progress[part.key].CNC >= 0 ? (
                <CircularProgressWithLabel
                  variant={
                    progress[part.key].status.CNC === "Started" ? "indeterminate" : "determinate"
                  }
                  color="secondary"
                  value={progress[part.key].CNC ? progress[part.key].CNC : 0}
                />
              ) : (
                <div />
              )}
            </Grid>
            <Grid item key="mm">
              {progress[part.key]["MANUAL MILL"] >= 0 ? (
                <CircularProgressWithLabel
                  variant={
                    progress[part.key].status["MANUAL MILL"] === "Started"
                      ? "indeterminate"
                      : "determinate"
                  }
                  color="error"
                  value={progress[part.key]["MANUAL MILL"] ? progress[part.key]["MANUAL MILL"] : 0}
                />
              ) : (
                <div />
              )}
            </Grid>
            <Grid item key="lathe">
              {progress[part.key].LATHE >= 0 ? (
                <CircularProgressWithLabel
                  variant={
                    progress[part.key].status.LATHE === "Started" ? "indeterminate" : "determinate"
                  }
                  color="error"
                  value={progress[part.key].LATHE ? progress[part.key].LATHE : 0}
                />
              ) : (
                <div />
              )}
            </Grid>
          </Grid>
        ),
        drawing: drawings[part.item_name] ? (
          <a href={drawings[part.item_name]} target="_blank" rel="noreferrer noopener">
            Open Drawing
          </a>
        ) : (
          <MDButton
            onClick={() => {
              handleLoadDrawing(part.item_name);
            }}
          >
            Load Drawing
          </MDButton>
        ),
        customize: editable ? (
          <div>
            <IconButton onClick={() => handleGoTo(`/projects/${pKey}/${part.key}`)}>
              <ArrowCircleRight />
            </IconButton>
            <div>
              <IconButton
                onClick={() => {
                  openDialog({
                    k: pKey,
                    handleAction: handleDelete,
                    title: "Are you sure to delete?",
                    context: `You are going to delete ${part.key || "N/A"}`,
                    sub: true,
                    kSub: part.key,
                  });
                }}
              >
                <Delete />
              </IconButton>
            </div>
            {generated[part.key] ? (
              <PDFDownloadLink
                document={
                  <MOView
                    partKey={part.key}
                    projectKey={part.projectKey}
                    mo={part.mo}
                    customer={pdfData.customer}
                    ipo={pdfData.ipo}
                    received={new Date(pdfData.id).toLocaleDateString("en-UK").split("T")[0]}
                    cpo={pdfData.cpo}
                    item={part.item_name}
                    delivery={
                      new Date(pdfData.estimated_delivery).toLocaleDateString("en-UK").split("T")[0]
                    }
                    quantity={Number(part.quantity)}
                    type={part.type || "pc"}
                    material={part.material}
                    jobs={pdfData.jobs}
                  />
                }
                fileName={`${part.mo}.pdf`}
              >
                Download
              </PDFDownloadLink>
            ) : (
              <MDButton
                onClick={async () => {
                  await handleGeneration(part.projectKey, part.key);
                }}
              >
                Generate MO
              </MDButton>
            )}
          </div>
        ) : (
          <div />
        ),
      })),
    ],
    handleFilter,
  };
}
