import { useSelector } from "react-redux";

export default function ongoingMoData() {
  let ongoing = [];
  const workers = useSelector((state) => state.workers);

  workers.forEach((worker) => {
    if (worker.occupied === true) {
      ongoing = [
        ...ongoing,
        ...worker.assigned_work.map((job) => ({
          mo: job,
          worker: worker.name,
          dep: worker.department,
        })),
      ];
    }
  });

  return ongoing;
}
