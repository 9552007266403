import PropTypes from "prop-types"
import { Image, StyleSheet,Text,View } from "@react-pdf/renderer";
import Table from "assets/images/pdf/motable.png"

const styles = StyleSheet.create({
    container:{
        position: 'absolute',
        width: '593px',
        height: '587px',
        left: '0px',
        top: '211px',
    },
    sg:{
        position: 'absolute',
        width: '53px',
        height: '20px',
        left: '531px',
        top: '441px',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '12px',
        textAlign: 'center',
        color: '#000000'    
    },
    pg:{
        position: 'absolute',
        width: '53px',
        height: '20px',
        left: '531px',
        top: '477px',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '12px',
        textAlign: 'center',
        color: '#000000'    
    },
    edm:{
        position: 'absolute',
        width: '53px',
        height: '20px',
        left: '531px',
        top: '516px',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '12px',
        textAlign: 'center',
        color: '#000000'    
    },
    wc:{
        position: 'absolute',
        width: '53px',
        height: '20px',
        left: '531px',
        top: '553px',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '12px',
        textAlign: 'center',
        color: '#000000'    
    },
    cnc:{
        position: 'absolute',
        width: '53px',
        height: '20px',
        left: '531px',
        top: '594px',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '12px',
        textAlign: 'center',
        color: '#000000'    
    },
    man:{
        position: 'absolute',
        width: '53px',
        height: '20px',
        left: '531px',
        top: '634px',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '12px',
        textAlign: 'center',
        color: '#000000'    
    },
    lathe:{
        position: 'absolute',
        width: '53px',
        height: '20px',
        left: '531px',
        top: '673px',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '12px',
        textAlign: 'center',
        color: '#000000'    
    }
})


export default function EstimatedTimes({times}){
    return(
        <View>
            <Image style={styles.container} src={Table}/>
            <Text style={styles.sg}>{times.sg}</Text>
            <Text style={styles.pg}>{times.pg}</Text>
            <Text style={styles.edm}>{times.edm}</Text>
            <Text style={styles.wc}>{times.wc}</Text>
            <Text style={styles.cnc}>{times.cnc}</Text>
            <Text style={styles.man}>{times.man}</Text>
            <Text style={styles.lathe}>{times.lathe}</Text>
        </View>
    );
}

EstimatedTimes.propTypes = {
    times: PropTypes.objectOf(PropTypes.any).isRequired,
}