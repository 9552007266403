/* eslint-disable no-unused-vars */
import { useState } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import FormControl from "@mui/material/FormControl";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Hooks
import { useAddNewCustomer } from "hooks/useCustomers";

// Form
import { useForm } from "react-hook-form";
import { Form } from "semantic-ui-react";

function NewCustomer({ handleStatus }) {
  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const [currency, setCurrency] = useState("");
  const [pT, setPT] = useState("");
  const [cName, setCName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState(0);
  const [work, setWork] = useState(0);
  const { handleSubmit } = useForm();
  const addNewCustomer = useAddNewCustomer();

  const clearSelection = () => {
    setName("");
    setAddress("");
    setCurrency("");
    setPT("");
    setCName("");
    setEmail("");
    setMobile("");
    setWork("");
  };

  const handleNameChange = (e) => setName(e.target.value);
  const handleCurrencyChange = (e) => setCurrency(e.target.value);
  const handlePTChange = (e) => setPT(e.target.value);
  const handleSaveCustomer = async () => {
    try {
      await addNewCustomer({
        id: Date.now(),
        name,
        address,
        currency,
        email,
        mobile,
        work,
        contact_name: cName,
        payment_term: pT,
      });
      handleStatus("New Customer Added!", `${name} added successfully.`, "success");
      clearSelection();
    } catch (e) {
      handleStatus("New Customer is not Added!", `Customer insertation failed.`, "error");
    }
  };

  return (
    <Form onSubmit={handleSubmit(handleSaveCustomer)}>
      <MDBox pt={6} pb={3}>
        <Card>
          <MDBox
            mx={2}
            mt={-3}
            py={3}
            px={2}
            variant="gradient"
            bgColor="info"
            borderRadius="lg"
            coloredShadow="info"
          >
            <MDTypography variant="h6" color="white">
              Add New Customer
            </MDTypography>
          </MDBox>
          <MDBox mx={2} mt={-3} py={3} px={2}>
            <MDBox pt={2} pb={1}>
              <Grid container spacing={2}>
                <Grid item xs={4} key={0}>
                  <FormControl>
                    <MDInput
                      type="text"
                      value={name}
                      onChange={handleNameChange}
                      label="Customer's Name"
                      required
                    />
                  </FormControl>
                </Grid>
                {/* <Grid item xs={8} key={1}>
                                    <FormControl>
                                        <MDInput type="text" label="Address" onChange={handleAddressChange} required/>
                                    </FormControl>
                                </Grid> */}
              </Grid>
            </MDBox>
            <MDBox pt={2} pb={1}>
              <Grid container spacing={2}>
                <Grid item xs={4} key={0}>
                  <FormControl>
                    <MDInput
                      type="text"
                      value={currency}
                      label="Currency"
                      onChange={handleCurrencyChange}
                      required
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={8} key={1}>
                  <FormControl>
                    <MDInput
                      type="number"
                      value={pT}
                      label="Payment Term"
                      onChange={handlePTChange}
                      required
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </MDBox>
            {/* <Card>
                            <MDBox
                            mx={2}
                            mt={2}
                            py={1}
                            px={5}
                            variant="contained"
                            bgColor="success"
                            borderRadius="lg"
                            coloredShadow="info"
                            >
                                <MDTypography variant="h9" color="white">
                                    Contact Details
                                </MDTypography>
                            </MDBox>
                            <MDBox
                                mx={2}
                                mt={-3}
                                py={3}
                                px={2}
                            >
                                <MDBox pt={2} pb={1}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={6} key={0}>
                                            <FormControl>
                                                <MDInput type="text" onChange={handleCNameChange} label="Contact Name" required/>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={6} key={1}>
                                            <FormControl>
                                                <MDInput type="email" label="Email" onChange={handleEmailChange} required/>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </MDBox>
                                <MDBox pt={2} pb={1}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={6} key={0}>
                                            <FormControl>
                                                <MDInput type="tel" label="Phone(Mobile)" onChange={handleMobileChange} pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}]" required/>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={6} key={1}>
                                            <FormControl>
                                                <MDInput type="tel" label="Phone(Work)" onChange={handleWorkPChange} pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}]" required/>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </MDBox>
                            </MDBox>
                        </Card> */}
            <MDBox mx={2} mt={1} py={3} px={2}>
              <MDButton color="info" type="submit">
                Add
              </MDButton>
            </MDBox>
          </MDBox>
        </Card>
      </MDBox>
    </Form>
  );
}

NewCustomer.propTypes = {
  handleStatus: PropTypes.func.isRequired,
};

export default NewCustomer;
