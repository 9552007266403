import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import CircularLoading from "items/CircularLoading";
import PropTypes from "prop-types";
import { useCallback, useState } from "react";
/**
 *
 * @returns
 */
export default function useAlertDialog() {
  const [props, setProps] = useState();
  const [open, setOpen] = useState(false);
  function handleOpen(propsT) {
    setProps(propsT);
    setOpen(true);
  }
  function handleClose() {
    setOpen(false);
  }

  const AlertDialog = useCallback(() => {
    const allProps = { ...props, ...{ handleClose, open } };
    return <AlertDialogComponent {...allProps} />;
  }, [open]);

  return [handleOpen, AlertDialog];
}

/**
 *
 * @param {string} k key for the firebase document
 * @param {boolean} open whether to open the dialog or not
 * @param {Function} handleclose handle dialog closing
 * @returns
 */
function AlertDialogComponent({ k, open, handleClose, handleAction, title, context, sub, kSub }) {
  const [loading, setLoading] = useState(false);
  return (
    <div>
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{context}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          {loading ? (
            <CircularLoading />
          ) : (
            <Button
              onClick={async () => {
                setLoading(true);
                if (sub) {
                  await handleAction(k, kSub);
                } else {
                  await handleAction(k);
                }
                setLoading(false);
                handleClose();
              }}
              autoFocus
            >
              Done
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
}

AlertDialogComponent.propTypes = {
  k: PropTypes.string,
  kSub: PropTypes.string,
  open: PropTypes.bool,
  handleAction: PropTypes.func,
  handleClose: PropTypes.func,
  title: PropTypes.string,
  context: PropTypes.string,
  sub: PropTypes.bool,
};

AlertDialogComponent.defaultProps = {
  k: "",
  kSub: "",
  open: false,
  handleAction: () => {},
  handleClose: () => {},
  title: "",
  context: "",
  sub: false,
};
