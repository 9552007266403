/* eslint-disable react/function-component-definition */
/* eslint-disable arrow-body-style */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/button-has-type */
/* eslint-disable no-unused-vars */
/* eslint-disable react/self-closing-comp */

import React from "react";

import PropTypes from "prop-types";

import "./s-g.css";
import MDButton from "components/MDButton";
import { Stack, Tooltip, Typography } from "@mui/material";
import useGetMachinesPerformances from "hooks/useGetMachinesPerformances";

const SG = (props) => {
  const { machines, workers } = props;
  const sg = machines.filter((machine) => machine.department === "SG");
  const percs = useGetMachinesPerformances(sg);

  const data = {
    bsg: sg.filter((machine) => machine.key === "bsgm1")[0],
  };

  const getColor = (btn) => {
    if (btn.running) {
      return "success";
    }
    if (btn.partoff) {
      return "partoff";
    }
    if (btn.working) {
      return "info";
    }

    return "error";
  };

  const getMessage = (_button) => {
    let msg = "";
    try {
      const matches = workers.filter((w) => parseInt(w.id, 10) === parseInt(_button.worker, 10));
      msg = (
        <Stack>
          <Typography variant="body">Machine: {_button.name}</Typography>
          <Typography variant="body">Job: {_button.assigned_work}</Typography>
          <Typography variant="body">
            Operator:{" "}
            {_button.worker === "N/A" || !_button.worker ? _button.worker : matches[0].name}
          </Typography>
          <Typography variant="body">Utilization: {percs[_button.key] || 0}%</Typography>
        </Stack>
      );
    } catch (e) {
      msg = "Loading...";
    }
    return msg;
  };

  return (
    <div className={`s-g-container ${props.rootClassName} `}>
      <Tooltip title={getMessage(data.bsg)}>
        <MDButton color={getColor(data.bsg)} size="large" className="s-g-button button">
          {props.bsg}
        </MDButton>
      </Tooltip>
    </div>
  );
};

SG.defaultProps = {
  bsg: "Big SG Machine",
  rootClassName: "",
};

SG.propTypes = {
  bsg: PropTypes.string,
  rootClassName: PropTypes.string,
  machines: PropTypes.arrayOf(PropTypes.any).isRequired,
  workers: PropTypes.arrayOf(PropTypes.any).isRequired,
};

export default SG;
