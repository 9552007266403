import { doc, setDoc, deleteDoc, updateDoc } from "firebase/firestore";
import { useDispatch } from "react-redux";
import { getJobs } from "redux/slices/jobsSlice";
import { db } from "../firebase";

export const useAddNewJob = () => {
  const dispatch = useDispatch();

  async function addNewJob(projectKey, partKey, data) {
    const dbRef = doc(db, "projects", projectKey, "parts", partKey, "jobs", data.process);
    await setDoc(dbRef, data);
    dispatch(getJobs({ projectKey, partKey }));
  }

  return addNewJob;
};

export function useDeleteJob() {
  const dispatch = useDispatch();

  async function deleteJob(projectKey, partKey, jobKey) {
    let docRef = doc(db, "projects", projectKey, "parts", partKey, "jobs", jobKey);
    await deleteDoc(docRef);
    docRef = doc(db, "jobs", jobKey);
    await deleteDoc(docRef);
    dispatch(getJobs({ projectKey, partKey }));
  }

  return deleteJob;
}

export function useUpdateJob() {
  const dispatch = useDispatch();

  async function updateJob(values, id, args, value) {
    const docRef = doc(
      db,
      "projects",
      args.projectKey,
      "parts",
      args.partKey,
      "jobs",
      values.process
    );
    await updateDoc(docRef, {
      [id]: value,
    });

    dispatch(getJobs({ projectKey: args.projectKey, partKey: args.partKey }));
  }

  return updateJob;
}
