/* eslint-disable react/function-component-definition */
/* eslint-disable arrow-body-style */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/button-has-type */
/* eslint-disable no-unused-vars */
/* eslint-disable react/self-closing-comp */

import React from 'react'

import PropTypes from 'prop-types'

import './press.css'
import MDButton from 'components/MDButton'

const Press = (props) => {
  return (
    <div className={`press-container ${props.rootClassName} `}>
      <div className="press-container1">
        <MDButton color="info" size="large" className="press-button button">{props.p110t}</MDButton>
      </div>
      <div className="press-container2">
        <MDButton color="info" size="large" className="press-button1 button">{props.p45t}</MDButton>
      </div>
    </div>
  )
}

Press.defaultProps = {
  rootClassName: '',
  p45t: 'Press 45 Ton',
  p110t: 'Presss 110 Ton',
}

Press.propTypes = {
  rootClassName: PropTypes.string,
  p45t: PropTypes.string,
  p110t: PropTypes.string,
}

export default Press
