/* eslint-disable react/function-component-definition */
/* eslint-disable arrow-body-style */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/button-has-type */
/* eslint-disable no-unused-vars */
/* eslint-disable react/self-closing-comp */

import PropTypes from "prop-types";

import "./p-g.css";
import MDButton from "components/MDButton";
import MDBox from "components/MDBox";
import { Stack, Tooltip, Typography } from "@mui/material";
import useGetMachinesPerformances from "hooks/useGetMachinesPerformances";

const PG = (props) => {
  const { machines, workers } = props;
  const pg = machines.filter((machine) => machine.department === "PG");
  const percs = useGetMachinesPerformances(pg);

  const data = {
    button1: pg.filter((machine) => machine.key === "pg5")[0],
    button2: pg.filter((machine) => machine.key === "pg3")[0],
    button3: pg.filter((machine) => machine.key === "pg6")[0],
    button4: pg.filter((machine) => machine.key === "pg2")[0],
    button5: pg.filter((machine) => machine.key === "pg7")[0],
    button6: pg.filter((machine) => machine.key === "pg1")[0],
    button: pg.filter((machine) => machine.key === "pg4")[0],
  };
  const getColor = (btn) => {
    if (btn.running) {
      return "success";
    }
    if (btn.partoff) {
      return "partoff";
    }
    if (btn.working) {
      return "info";
    }

    return "error";
  };

  const getMessage = (_button) => {
    let msg = "";
    try {
      const matches = workers.filter((w) => parseInt(w.id, 10) === parseInt(_button.worker, 10));
      msg = (
        <Stack>
          <Typography variant="body">Machine: {_button.name}</Typography>
          <Typography variant="body">Job: {_button.assigned_work}</Typography>
          <Typography variant="body">
            Operator:{" "}
            {_button.worker === "N/A" || !_button.worker ? _button.worker : matches[0].name}
          </Typography>
          <Typography variant="body">Utilization: {percs[_button.key] || 0}%</Typography>
        </Stack>
      );
    } catch (e) {
      msg = "Loading...";
    }
    return msg;
  };

  return (
    <div className={`p-g-container ${props.rootClassName} `}>
      <div className="p-g-container1">
        <Tooltip title={getMessage(data.button6)}>
          <MDBox mx={1}>
            <MDButton
              color={getColor(data.button6)}
              size="small"
              iconOnly
              className="p-g-button button"
            >
              {props.button6}
            </MDButton>
          </MDBox>
        </Tooltip>
        <Tooltip title={getMessage(data.button4)}>
          <MDBox mx={1}>
            <MDButton
              color={getColor(data.button4)}
              size="small"
              iconOnly
              className="p-g-button1 button"
            >
              {props.button4}
            </MDButton>
          </MDBox>
        </Tooltip>
        <Tooltip title={getMessage(data.button2)}>
          <MDBox mx={1}>
            <MDButton
              color={getColor(data.button2)}
              size="small"
              iconOnly
              className="p-g-button2 button"
            >
              {props.button2}
            </MDButton>
          </MDBox>
        </Tooltip>
        <Tooltip title={getMessage(data.button)}>
          <MDBox mx={1}>
            <MDButton
              color={getColor(data.button)}
              size="small"
              iconOnly
              className="p-g-button3 button"
            >
              {props.button}
            </MDButton>
          </MDBox>
        </Tooltip>
        <Tooltip title={getMessage(data.button1)}>
          <MDBox mx={1}>
            <MDButton
              color={getColor(data.button1)}
              size="small"
              iconOnly
              className="p-g-button4 button"
            >
              {props.button1}
            </MDButton>
          </MDBox>
        </Tooltip>
        <Tooltip title={getMessage(data.button3)}>
          <MDBox mx={1}>
            <MDButton
              color={getColor(data.button3)}
              size="small"
              iconOnly
              className="p-g-button5 button"
            >
              {props.button3}
            </MDButton>
          </MDBox>
        </Tooltip>
        <Tooltip title={getMessage(data.button5)}>
          <MDBox mx={1}>
            <MDButton
              color={getColor(data.button5)}
              size="small"
              iconOnly
              className="p-g-button6 button"
            >
              {props.button5}
            </MDButton>
          </MDBox>
        </Tooltip>
      </div>
    </div>
  );
};

PG.defaultProps = {
  button1: "PG5",
  button2: "PG3",
  button3: "PG6",
  button4: "PG2",
  button5: "PG7",
  button6: "PG1",
  rootClassName: "",
  button: "PG4",
};

PG.propTypes = {
  button1: PropTypes.string,
  button2: PropTypes.string,
  button3: PropTypes.string,
  button4: PropTypes.string,
  button5: PropTypes.string,
  button6: PropTypes.string,
  rootClassName: PropTypes.string,
  button: PropTypes.string,
  machines: PropTypes.arrayOf(PropTypes.any).isRequired,
  workers: PropTypes.arrayOf(PropTypes.any).isRequired,
};

export default PG;
