import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getDocs, collection, query, where, getDoc, doc } from "firebase/firestore";
// import { getFunctions, httpsCallable } from "firebase/functions";
import fetchJobs from "utils/fetchJobs";
import { db } from "../../firebase";

export const moSlice = createSlice({
  name: "mos",
  initialState: {},
  reducers: {},
  /* eslint-disable no-use-before-define */
  /* eslint-disable no-unused-vars */
  /* eslint-disable no-param-reassign */
  /* eslint-disable prefer-destructuring */
  extraReducers(builder) {
    builder.addCase(getMOs.fulfilled, (state, action) => {
      localStorage.setItem("mos", new Date(Date.now()).toTimeString().split(" ")[0]);
      const data = action.payload.data;
      state = { ...state, ...data };
      return state;
    });
    builder.addCase(getProjectMOs.fulfilled, (state, action) => {
      localStorage.setItem("mos", new Date(Date.now()).toTimeString().split(" ")[0]);
      const data = action.payload.data;
      state = { ...state, ...data };
      return state;
    });
    builder.addCase(getCompletedMOs.fulfilled, (state, action) => {
      localStorage.setItem("mos", new Date(Date.now()).toTimeString().split(" ")[0]);
      const data = action.payload.data;
      state = { ...state, ...data };
      return state;
    });
    builder.addCase(getCompletedMO.fulfilled, (state, action) => {
      localStorage.setItem("mos", new Date(Date.now()).toTimeString().split(" ")[0]);
      const data = action.payload.data;
      state = { ...state, ...data };
      return state;
    });
  },
});

export const getMOs = createAsyncThunk("mos/getMos", async () => {
  const projects = await getDocs(
    query(collection(db, `projects`), where("completed", "!=", true))
  ).then((querySnapshot) => {
    const newData = querySnapshot.docs.map((document) => {
      const originalData = document.data();
      originalData.key = document.id;
      try {
        originalData.createdAt = originalData.createdAt.seconds * 1000;
        originalData.updatedAt = originalData.updatedAt.seconds * 1000;
      } catch (e) {
        originalData.createdAt = 1673254226;
        originalData.updatedAt = 1673254226;
      }
      return originalData;
    });
    return newData;
  });
  const allParts = [];
  const moObj = {};
  await Promise.all(
    projects.map(async (project) => {
      const partsData = await getDocs(
        query(collection(db, `projects/${project.key}/parts`), where("status", "!=", "Completed"))
      ).then((querySnapshot) => {
        const newData = querySnapshot.docs.map(async (document) => {
          const originalData = document.data();
          originalData.key = document.id;
          originalData.ipo = project.ipo;
          originalData.projectKey = project.key;
          originalData.estimated_delivery = new Date(
            project.estimated_delivery.seconds * 1000
          ).toLocaleDateString("en-UK");
          originalData.ed = project.estimated_delivery.seconds * 1000;
          try {
            originalData.createdAt = originalData.createdAt.seconds * 1000;
            originalData.updatedAt = originalData.updatedAt.seconds * 1000;
          } catch (e) {
            originalData.createdAt = 1673254226;
            originalData.updatedAt = 1673254226;
          }
          try {
            originalData.completedAt = originalData.completedAt.seconds * 1000;
          } catch (e) {
            originalData.completedAt = 1673254226;
          }
          allParts.push(originalData);
          return originalData;
        });
        return newData;
      });
      return partsData;
    })
  );
  await Promise.all(
    allParts.map(async (part, index) => {
      const jobs = await fetchJobs(part.projectKey, part.key);
      // allParts[index] = { ...part, ...{ jobs } };
      moObj[part.key] = { ...part, ...{ jobs } };
    })
  );
  return { data: moObj };
});

export const getProjectMOs = createAsyncThunk("mos/getProjectMos", async (ipo) => {
  const projects = await getDocs(query(collection(db, `projects`), where("ipo", "==", ipo))).then(
    (querySnapshot) => {
      const newData = querySnapshot.docs.map((document) => {
        const originalData = document.data();
        originalData.key = document.id;
        try {
          originalData.createdAt = originalData.createdAt.seconds * 1000;
          originalData.updatedAt = originalData.updatedAt.seconds * 1000;
        } catch (e) {
          originalData.createdAt = 1673254226;
          originalData.updatedAt = 1673254226;
        }
        return originalData;
      });
      return newData;
    }
  );
  const allParts = [];
  const moObj = {};
  await Promise.all(
    projects.map(async (project) => {
      const partsData = await getDocs(query(collection(db, `projects/${project.key}/parts`))).then(
        (querySnapshot) => {
          const newData = querySnapshot.docs.map(async (document) => {
            const originalData = document.data();
            originalData.key = document.id;
            originalData.ipo = project.ipo;
            originalData.projectKey = project.key;
            originalData.estimated_delivery = new Date(
              project.estimated_delivery.seconds * 1000
            ).toLocaleDateString("en-UK");
            originalData.ed = project.estimated_delivery.seconds * 1000;
            try {
              originalData.createdAt = originalData.createdAt.seconds * 1000;
              originalData.updatedAt = originalData.updatedAt.seconds * 1000;
            } catch (e) {
              originalData.createdAt = 1673254226;
              originalData.updatedAt = 1673254226;
            }
            try {
              originalData.completedAt = originalData.completedAt.seconds * 1000;
            } catch (e) {
              originalData.completedAt = 1673254226;
            }
            allParts.push(originalData);
            return originalData;
          });
          return newData;
        }
      );
      return partsData;
    })
  );
  await Promise.all(
    allParts.map(async (part, index) => {
      const jobs = await fetchJobs(part.projectKey, part.key);
      // allParts[index] = { ...part, ...{ jobs } };
      moObj[part.key] = { ...part, ...{ jobs } };
    })
  );
  return { data: moObj };
});

export const getCompletedMOs = createAsyncThunk("mos/getCompletedMos", async () => {
  const completedMOs = {};
  const today = new Date(Date.now());
  const moDocs = await getDocs(
    query(
      collection(db, `mos`),
      where("estimated_delivery", ">", new Date(today.getFullYear(), today.getMonth(), 1))
    )
  );
  moDocs.forEach((moDoc) => {
    completedMOs[moDoc.id] = {
      ...moDoc.data(),
      ...{
        key: moDoc.id,
        estimated_delivery: new Date(
          moDoc.data().estimated_delivery.seconds * 1000
        ).toLocaleDateString("en-UK"),
        ed: moDoc.data().estimated_delivery.seconds * 1000,
        completedAt: moDoc.data().completedAt
          ? moDoc.data().completedAt.seconds * 1000
          : 1673254226,
        createdAt: moDoc.data().createdAt ? moDoc.data().createdAt.seconds * 1000 : 1673254226,
        updatedAt: moDoc.data().updatedAt ? moDoc.data().updatedAt.seconds * 1000 : 1673254226,
        jobs: Array.from(moDoc.data().jobs).map((job) => {
          // job.createdAt = job.createdAt.seconds * 1000;
          job.updatedAt = job.updatedAt ? job.updatedAt.seconds * 1000 : 1673254226;
          return job;
        }),
      },
    };
  });
  return { data: completedMOs };
});

export const getCompletedMO = createAsyncThunk("mos/getCompletedMo", async (path) => {
  const completedMOs = {};
  const moDoc = await getDoc(doc(db, path));
  completedMOs[moDoc.id] = {
    ...moDoc.data(),
    ...{
      key: moDoc.id,
      estimated_delivery: new Date(
        moDoc.data().estimated_delivery.seconds * 1000
      ).toLocaleDateString("en-UK"),
      ed: moDoc.data().estimated_delivery.seconds * 1000,
      completedAt: moDoc.data().completedAt ? moDoc.data().completedAt.seconds * 1000 : 1673254226,
      createdAt: moDoc.data().createdAt ? moDoc.data().createdAt.seconds * 1000 : 1673254226,
      updatedAt: moDoc.data().updatedAt ? moDoc.data().updatedAt.seconds * 1000 : 1673254226,
      jobs: Array.from(moDoc.data().jobs).map((job) => {
        // job.createdAt = job.createdAt.seconds * 1000;
        job.updatedAt = job.updatedAt ? job.updatedAt.seconds * 1000 : 1673254226;
        return job;
      }),
    },
  };

  return { data: completedMOs };
});

export default moSlice.reducer;
