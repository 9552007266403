/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
// import DataTable from "examples/Tables/DataTable";

// Data
import workersTableData from "layouts/reports/data/workers";
import machinesTableData from "layouts/reports/data/machines";
import { useMemo, useState } from "react";

import Select from "react-select";
import { useSelector } from "react-redux";
import MDButton from "components/MDButton";
import WorkerPerformance from "docs/csv/WP/WorkerPerformance";
import fetchWorker from "utils/getWorkerPerformance";
import fetchMachine from "utils/getMachinePerformance";
import MachinePerformance from "docs/csv/MP/MachinePerformance";
import { CircularProgress } from "@mui/material";
import MDDatePicker from "components/MDDatePicker";

/* eslint-disable no-unused-vars */

function Reports() {
  const workers = useSelector((state) => state.workers);
  const machines = useSelector((state) => state.machines);

  const [type, setType] = useState({ label: "Worker Performance", value: "wp" });
  const [generated, setGenerated] = useState(false);
  const [workerData, setWorkerData] = useState([]);
  const [machineData, setMachineData] = useState([]);
  const [worker, setWorker] = useState();
  const [machine, setMachine] = useState();
  const [loading, setLoading] = useState(false);
  const [dates, setDates] = useState({
    startDate: new Date(new Date(Date.now).getFullYear, new Date(Date.now).getMonth(), 1),
    endDate: new Date(Date.now()),
  });
  const [performance, setPerformance] = useState(0);

  const tables = {
    wp: "Worker Performance",
    mp: "Machine Performance",
  };
  const data = {
    wp: workersTableData(),
    mp: machinesTableData(),
  };

  const handleChangeType = (event) => {
    setType(event);
    setGenerated(false);
    setPerformance(0);
  };

  const handleChangeOption = (event) => {
    if (type.value === "wp") {
      setWorker(event);
    } else if (type.value === "mp") {
      setMachine(event);
    }
    setGenerated(false);
    setPerformance(0);
  };

  const handleDateChange = (dateRange) => {
    setDates({
      startDate: dateRange[0],
      endDate: dateRange[1],
    });
    setPerformance(0);
  };

  const handleGeneration = async () => {
    setLoading(true);
    if (type.value === "wp") {
      const res = await fetchWorker(worker.value, dates.startDate, dates.endDate);
      setWorkerData(res.data);
      setPerformance(res.performance);
    } else if (type.value === "mp") {
      const res = await fetchMachine(machine.value, dates.startDate, dates.endDate);
      setMachineData(res.data);
      setPerformance(res.performance);
    }
    setLoading(false);
    setGenerated(true);
  };

  const options = [
    { label: "Worker Performance", value: "wp" },
    { label: "Machine Performance", value: "mp" },
  ];

  const workerOptions = workers.map((_worker) => ({ label: _worker.name, value: _worker.id }));
  const machineOptions = machines.map((_machine) => ({
    label: _machine.name,
    value: _machine.key,
  }));

  const GenerateButton = useMemo(() =>
    loading ? (
      <CircularProgress />
    ) : (
      <MDButton
        color="info"
        onClick={async () => {
          await handleGeneration();
        }}
        disabled={!worker && !machine}
      >
        Generate Report
      </MDButton>
    )
  );

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Card>
        <Select
          value={type}
          options={options}
          onChange={handleChangeType}
          placeholder="Select the report type"
        />
      </Card>
      <MDBox mx={1} mt={3}>
        {" "}
      </MDBox>
      <Card>
        <Select
          options={type.value === "wp" ? workerOptions : machineOptions}
          onChange={handleChangeOption}
          placeholder={`Select the ${tables[type.value].split(" ")[0]}`}
        />
      </Card>
      <MDBox mx={1} mt={3}>
        {" "}
      </MDBox>
      <Card>
        <MDDatePicker
          options={{ mode: "range" }}
          input={{ placeholder: "Select the date range" }}
          onClose={handleDateChange}
        />
      </Card>
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox mx={2} mt={3} py={3} px={5}>
                {" "}
              </MDBox>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  {tables[type.value]} Report
                </MDTypography>
              </MDBox>
              <MDBox py={3}>
                <MDTypography variant="h6" textAlign="center">
                  Overall Performance = {performance.toFixed(2)}%
                </MDTypography>
              </MDBox>
              <MDBox pt={3} pb={3} mx="auto">
                {generated ? (
                  <div>
                    {type.value === "wp" ? (
                      <WorkerPerformance data={workerData} />
                    ) : (
                      <MachinePerformance data={machineData} />
                    )}
                  </div>
                ) : (
                  GenerateButton
                )}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Reports;
