import MDBox from "components/MDBox";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";
import workingHourData from "../data/workingHourData";

export default function TotalWH() {
  const tWHData = workingHourData();

  return (
    <MDBox mb={1.5}>
      <ComplexStatisticsCard
        color="success"
        icon="schedule"
        title="Total Working Hours"
        count={tWHData.count}
        percentage={{
          color: tWHData.percentage > 0 ? "success" : "error",
          amount: `${tWHData.percentage}%`,
          label: "than Last Month",
        }}
      />
    </MDBox>
  );
}
