import MDBox from "components/MDBox";
import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
import workersEffectiveHoursData from "../data/workersEffectiveHoursData";

export default function WorkersEffectiveHours() {
  const wEHData = workersEffectiveHoursData();

  return (
    <MDBox mb={3}>
      <ReportsBarChart
        color="secondary"
        title="Effective Working Hours"
        description="This Month Performance"
        date={`updated at ${localStorage.getItem("worker_performances")}`}
        chart={wEHData}
      />
    </MDBox>
  );
}
