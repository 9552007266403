/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import { Stack } from "@mui/material";
import { Link } from "react-router-dom";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React examples
import DefaultItem from "examples/Items/DefaultItem";
import upcomingDeliveryData from "../data/upcomingDeliveryData";

function UpcomingDeliveries() {
  const upcomingDeliveries = upcomingDeliveryData();
  return (
    <Card sx={{ height: "100%" }}>
      <MDBox pt={2} px={2} lineHeight={1}>
        <MDTypography variant="h6" fontWeight="medium">
          Upcoming Deliveries
        </MDTypography>
      </MDBox>
      <Stack direction="row">
        <MDBox p={2} key={0}>
          {upcomingDeliveries
            .splice(0, Math.round(upcomingDeliveries.length / 2))
            .map((delivery) => (
              <MDBox mt={2.5} key={delivery.id}>
                <Link to={`/projects/${delivery.key}`}>
                  <DefaultItem
                    color={delivery.left > 0 ? "info" : "error"}
                    icon={delivery.completed ? "task" : "notifications_active"}
                    title={`${delivery.ipo}-${delivery.customer}`}
                    description={`Due on: ${new Date(
                      delivery.estimated_delivery
                    ).toDateString()} (${delivery.left} days ${
                      delivery.left > 0 ? "left" : "late"
                    })`}
                  />
                </Link>
              </MDBox>
            ))}
        </MDBox>
        <MDBox p={2} key={1}>
          {upcomingDeliveries.map((delivery) => (
            <MDBox mt={2.5} key={delivery.id}>
              <Link to={`/projects/${delivery.key}`}>
                <DefaultItem
                  color={delivery.left > 0 ? "info" : "error"}
                  icon={delivery.completed ? "task" : "notifications_active"}
                  title={`${delivery.ipo}-${delivery.customer}`}
                  description={`Due on: ${new Date(delivery.estimated_delivery).toDateString()} (${
                    delivery.left
                  } days ${delivery.left > 0 ? "left" : "late"})`}
                />
              </Link>
            </MDBox>
          ))}
        </MDBox>
      </Stack>
    </Card>
  );
}

export default UpcomingDeliveries;
