/* eslint-disable react/function-component-definition */
/* eslint-disable arrow-body-style */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/button-has-type */
/* eslint-disable no-unused-vars */
/* eslint-disable react/self-closing-comp */

import React from "react";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import { Card, Grid, IconButton } from "@mui/material";
import { Refresh } from "@mui/icons-material";

import { getMachines } from "redux/slices/machinesSlice";

import { useSelector, useDispatch } from "react-redux";

import CNCM from "./components/c-n-c-m";
import PG from "./components/p-g";
import EDM from "./components/e-d-m";
import SG from "./components/s-g";
import Lathe from "./components/lathe";
import Mill from "./components/mill";
import Press from "./components/press";
import "./index.css";

const WorkshopLayout = (props) => {
  const machines = useSelector((state) => state.machines);
  const workers = useSelector((state) => state.workers);
  const dispatch = useDispatch();
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Card>
          <MDBox
            mx={2}
            mt={-3}
            py={1}
            px={2}
            variant="gradient"
            bgColor="info"
            borderRadius="lg"
            coloredShadow="info"
          >
            <MDTypography>Machines</MDTypography>
          </MDBox>
          <IconButton
            onClick={() => {
              dispatch(getMachines());
            }}
          >
            <Refresh />
          </IconButton>
          <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
          >
            <Grid item xs={3}>
              {" "}
              <div style={{ overflow: "auto", alignItems: "center" }}>
                {machines.length === 32 ? (
                  <MDBox pt={6}>
                    <div className="workshop-layout-container">
                      <div className="workshop-layout-container01">
                        <div className="workshop-layout-container02">
                          <div className="workshop-layout-container03">
                            <CNCM
                              machines={machines}
                              workers={workers}
                              rootClassName="c-n-c-m-root-class-name"
                            ></CNCM>
                          </div>
                        </div>
                        <div className="workshop-layout-container05">
                          <PG
                            machines={machines}
                            workers={workers}
                            rootClassName="p-g-root-class-name"
                          ></PG>
                          <div className="workshop-layout-container07">
                            <EDM
                              machines={machines}
                              workers={workers}
                              rootClassName="e-d-m-root-class-name"
                            ></EDM>
                          </div>
                        </div>
                        <div className="workshop-layout-container08">
                          <div className="workshop-layout-container09">
                            <SG
                              machines={machines}
                              workers={workers}
                              rootClassName="s-g-root-class-name"
                            ></SG>
                          </div>
                          <div className="workshop-layout-container10">
                            <Lathe
                              machines={machines}
                              workers={workers}
                              rootClassName="lathe-root-class-name"
                            ></Lathe>
                          </div>
                        </div>
                        <div className="workshop-layout-container11">
                          <div className="workshop-layout-container12">
                            <Mill
                              machines={machines}
                              workers={workers}
                              rootClassName="mill-root-class-name"
                            ></Mill>
                          </div>
                          <div className="workshop-layout-container13">
                            <Press rootClassName="press-root-class-name"></Press>
                          </div>
                        </div>
                      </div>
                    </div>
                  </MDBox>
                ) : (
                  <div />
                )}
              </div>
            </Grid>
          </Grid>
        </Card>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
};

export default WorkshopLayout;
