/* eslint-disable import/no-unresolved */
/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";

// react-router-dom components

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
// import Grid from "@mui/material/Grid";
// import MuiLink from "@mui/material/Link";

// // @mui icons
// import FacebookIcon from "@mui/icons-material/Facebook";
// import GitHubIcon from "@mui/icons-material/GitHub";
// import GoogleIcon from "@mui/icons-material/Google";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import { useSignIn, useForgotPassword } from "hooks/useAuth";

import { useNavigate } from "react-router-dom";
import { Alert, Snackbar } from "@mui/material";

function Basic() {
  /**
   * Hooks calls
   */
  const [rememberMe, setRememberMe] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [signIn, logged, error] = useSignIn();

  const navigate = useNavigate();
  const [resetPassword, sent, notSent] = useForgotPassword();
  useEffect(() => {
    if (logged) {
      navigate("/dashboard");
    }
    if (error) {
      console.log(error);
    }
  }, [logged, error]);

  /**
   * Handlers
   */

  /**
   *
   * @returns null
   */
  const handleSetRememberMe = () => setRememberMe(!rememberMe);
  /**
   *
   * @returns null
   */
  const handleChangeEmail = (e) => setEmail(e.target.value);
  /**
   *
   * @returns null
   */
  const handleChangePassword = (e) => setPassword(e.target.value);
  /**
   *
   * @returns null
   */
  const handleSignIn = async () => {
    await signIn(email, password);
  };

  return (
    <BasicLayout image={bgImage}>
      <Snackbar
        open={sent}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert severity="success" sx={{ width: "100%" }}>
          Password reset link sent to your e-mail address. Check inbox!
        </Alert>
      </Snackbar>
      <Snackbar
        open={notSent}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert severity="error" sx={{ width: "100%" }}>
          Something went wrong. Try again in few minutes!
        </Alert>
      </Snackbar>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Sign in
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={2}>
              <MDInput type="email" label="Email" fullWidth onChange={handleChangeEmail} />
            </MDBox>
            <MDBox mb={2}>
              <MDInput type="password" label="Password" fullWidth onChange={handleChangePassword} />
            </MDBox>
            <MDBox mb={2}>
              <MDTypography variant="caption" color="error">
                {error ? "Incorrect e-mail or password!" : ""}
              </MDTypography>
            </MDBox>
            <MDBox display="flex" alignItems="center" ml={-1}>
              <Switch checked={rememberMe} onChange={handleSetRememberMe} />
              <MDTypography
                variant="button"
                fontWeight="regular"
                color="text"
                onClick={handleSetRememberMe}
                sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
              >
                &nbsp;&nbsp;Remember me
              </MDTypography>
            </MDBox>
            <MDBox mt={4} mb={1}>
              <MDButton variant="gradient" color="info" fullWidth onClick={handleSignIn}>
                sign in
              </MDButton>
            </MDBox>
            <MDBox mt={3} mb={1} textAlign="center">
              <MDTypography variant="button" color="text">
                Forgot password?{" "}
                <MDTypography
                  component={MDButton}
                  onClick={() => {
                    resetPassword(email);
                  }}
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  Reset
                </MDTypography>
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default Basic;
