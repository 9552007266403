import MDBox from "components/MDBox";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";
import clearedMOsData from "../data/clearedMOsData";

export default function MOsCleared() {
  const cMOData = clearedMOsData();

  return (
    <MDBox mb={1.5}>
      <ComplexStatisticsCard
        icon="leaderboard"
        title="MOs Cleared"
        count={cMOData.count}
        percentage={{
          color: cMOData.percentage > 0 ? "success" : "error",
          amount: `${cMOData.percentage}%`,
          label: "than Last Month",
        }}
      />
    </MDBox>
  );
}
