import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getDocs, collection } from "firebase/firestore";
import { db } from "../../firebase";

export const jobsSlice = createSlice({
  name: "jobs",
  initialState: [{}],
  reducers: {},
  /* eslint-disable no-use-before-define */
  /* eslint-disable no-unused-vars */
  /* eslint-disable no-param-reassign */
  /* eslint-disable prefer-destructuring */
  extraReducers(builder) {
    builder.addCase(getJobs.fulfilled, (state, action) => {
      localStorage.setItem("jobs", new Date(Date.now()).toTimeString().split(" ")[0]);
      const data = action.payload.data;
      return data;
    });
  },
});

export const getJobs = createAsyncThunk("jobs/getJobs", async ({ projectKey, partKey }) => {
  const data = await getDocs(collection(db, "projects", projectKey, "parts", partKey, "jobs")).then(
    (querySnapshot) => {
      const partData = querySnapshot.docs.map((doc) => {
        const originalData = doc.data();
        originalData.key = doc.id;
        try {
          originalData.createdAt = originalData.createdAt.seconds * 1000;
          originalData.updatedAt = originalData.updatedAt.seconds * 1000;
        } catch (e) {
          originalData.createdAt = 1673254226;
          originalData.updatedAt = 1673254226;
        }
        return originalData;
      });
      return partData;
    }
  );
  return { data };
});

export default jobsSlice.reducer;
