/* eslint-disable import/no-unresolved */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
// import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
// import DataTable from "examples/Tables/DataTable";

import DefaultDoughnutChart from "examples/Charts/DoughnutCharts/DefaultDoughnutChart";
import DualDoughnutChart from "examples/Charts/DoughnutCharts/DualDoughnutChart";

// Data
import data from "./data";

function AvailableHours() {
  const doughnutData = data();
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <MDBox
              mx={2}
              mt={-3}
              py={3}
              px={2}
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
            >
              <MDTypography variant="h6" color="white">
                Available Hours
              </MDTypography>
            </MDBox>
            {Object.keys(doughnutData).map((key) => (
              <div key={key}>
                <MDBox
                  mx={2}
                  mt={3}
                  mb={3}
                  py={1}
                  px={1}
                  variant="gradient"
                  bgColor="success"
                  borderRadius="lg"
                  coloredShadow="info"
                >
                  <MDTypography variant="h7" color="white">
                    {key}
                  </MDTypography>
                </MDBox>
                <Grid container spacing={2}>
                  <Grid item xs={4} key={`${key}-0`}>
                    <DualDoughnutChart
                      icon={{ color: "success", component: "hourglass_top" }}
                      title={doughnutData[key][0][0].datasets.label}
                      description="Available Hours"
                      chart1={doughnutData[key][0][0]}
                      chart2={doughnutData[key][0][1]}
                    />
                  </Grid>
                  <Grid item xs={4} key={`${key}-1`}>
                    <DefaultDoughnutChart
                      icon={{ color: "success", component: "hourglass_top" }}
                      title={doughnutData[key][1].datasets.label}
                      description="Available Hours"
                      chart={doughnutData[key][1]}
                    />
                  </Grid>
                  <Grid item xs={4} key={`${key}-2`}>
                    <DefaultDoughnutChart
                      icon={{ color: "success", component: "hourglass_top" }}
                      title={doughnutData[key][2].datasets.label}
                      description="Available Hours"
                      chart={doughnutData[key][2]}
                    />
                  </Grid>
                </Grid>
              </div>
            ))}
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default AvailableHours;
