/* eslint-disable import/no-unresolved */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// Data
import MOsCleared from "./components/MOsCleared";
import OngoingMos from "./components/ongoingMOs";
import POsCleared from "./components/POsCleared";
import TotalScrapTime from "./components/TotalScrapTime";
import TotalWH from "./components/TotalWH";
import UpcomingDeliveries from "./components/UpcomingDeliveries";
import WorkersEffectiveHours from "./components/WorkersEffectiveHours";
import WorkersPerformances from "./components/WorkersPerformances";

// Dashboard components
// import Projects from "layouts/dashboard/components/Projects";
// import OrdersOverview from "layouts/dashboard/components/OrdersOverview";

function Dashboard() {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={3}>
            <MOsCleared />
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <POsCleared />
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <TotalWH />
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <TotalScrapTime />
          </Grid>
        </Grid>
        <MDBox mt={4.5}>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <WorkersPerformances />
            </Grid>
            <Grid item xs={6}>
              <WorkersEffectiveHours />
            </Grid>
          </Grid>
        </MDBox>
        <MDBox mt={3.5}>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <UpcomingDeliveries />
            </Grid>
            <Grid item xs={6}>
              <OngoingMos />
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Dashboard;
