import { ref,uploadBytes } from "firebase/storage";
import { storage } from "../firebase";

/* eslint-disable camelcase */

const uploadDrawing =async (file,part_number)=>{
    const fileRef = ref(storage,`drawings/${part_number}.pdf`);
    await uploadBytes(fileRef,file);
    return "post"
}

export default uploadDrawing;