import { collection, getDocs, orderBy, query, where } from "firebase/firestore";
import { db } from "../firebase";
import { getTotalHoursPerMonth } from "./getMonthlyTotalHours";
/* eslint-disable camelcase */
const fetchMachine = async (machine, startDate, endDate) => {
  const dbRef = collection(db, "machines_data");
  const q = query(
    dbRef,
    where("machine", "==", machine),
    orderBy("date", "desc"),
    where("date", ">=", startDate),
    where("date", "<=", endDate)
  );
  const snapshot = await getDocs(q);
  const data = snapshot.docs.map((doc) => ({
    ...doc.data(),
    ...{
      "machine type": doc.data().machine_type,
      hours: (Math.round(Number(doc.data().consumed_time) * 4) / 4).toFixed(2),
      dt: doc.data().date,
      date: new Date(doc.data().date.seconds * 1000).toLocaleDateString("en-UK"),
    },
  }));
  const getPerc = () => {
    const hoursPerMonth = {};

    data.forEach((item) => {
      const date = new Date(item.dt.seconds * 1000);
      hoursPerMonth[`${date.getFullYear()}-${date.getMonth()}`] = getTotalHoursPerMonth(
        date.getMonth(),
        date.getFullYear()
      );
    });
    const totalHours = Object.values(hoursPerMonth).reduce((a, b) => a + b, 0);
    return (data.reduce((n, { consumed_time }) => n + consumed_time, 0) / totalHours) * 100;
  };
  return {
    data,
    performance: getPerc(),
  };
};

export default fetchMachine;
