import { configureStore } from "@reduxjs/toolkit";
import workersReducer from "./slices/workersSlice";
import jobsReducer from "./slices/jobsSlice";
import projectsReducer from "./slices/projectsSlice";
import partsReducer from "./slices/partsSlice";
import customersReducer from "./slices/customersSlice";
import moReducer from "./slices/moSlice";
import machinesReducer from "./slices/machinesSlice"
import workersPerformancesReducer from "./slices/workersPerformancesSlice"
import machinesPerformancesReducer from "./slices/machinesPerformancesSlice"


export default configureStore({
  reducer: {
    workers: workersReducer,
    jobs: jobsReducer,
    projects: projectsReducer,
    parts: partsReducer,
    customers: customersReducer,
    mos: moReducer,
    machines: machinesReducer,
    workersPerformances: workersPerformancesReducer,
    machinesPerformances: machinesPerformancesReducer,
  },
});
