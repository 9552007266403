import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getDocs, collection } from "firebase/firestore";
import { ref, getDownloadURL } from "firebase/storage";
import { db, storage } from "../../firebase";

export const partsSlice = createSlice({
  name: "parts",
  initialState: [],
  reducers: {},
  /* eslint-disable no-use-before-define */
  /* eslint-disable no-unused-vars */
  /* eslint-disable no-param-reassign */
  /* eslint-disable prefer-destructuring */
  extraReducers(builder) {
    builder.addCase(getParts.fulfilled, (state, action) => {
      localStorage.setItem("parts", new Date(Date.now()).toTimeString().split(" ")[0]);
      const data = action.payload.data;
      return data;
    });
    builder.addCase(getDrawings.fulfilled, (state, action) => {
      const data = action.payload.data;
      return data;
    });
  },
});

export const getParts = createAsyncThunk("parts/getParts", async (projectKey) => {
  const data = await getDocs(collection(db, `projects/${projectKey}/parts`)).then(
    (querySnapshot) => {
      const newData = querySnapshot.docs.map((doc) => {
        const originalData = doc.data();
        originalData.key = doc.id;
        originalData.projectKey = projectKey;
        try {
          originalData.createdAt = originalData.createdAt.seconds * 1000;
          originalData.updatedAt = originalData.updatedAt.seconds * 1000;
        } catch (e) {
          originalData.createdAt = 1673254226;
          originalData.updatedAt = 1673254226;
        }
        return { ...originalData };
      });
      return newData;
    }
  );
  return { data, project: projectKey };
});

export const getDrawings = createAsyncThunk("parts/getdrawings", async (parts) => {
  const data = await Promise.all(
    parts.map(async (part) => {
      const dRef = ref(storage, `drawings/${part.item_name}.pdf`);
      let url = "#";
      try {
        url = await getDownloadURL(dRef);
      } catch (e) {
        url = "#";
      }
      return { ...part, ...{ drawing: url } };
    })
  );

  return { data };
});

export default partsSlice.reducer;
