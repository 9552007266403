/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
/* eslint-disable no-unused-vars */
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { Chip, Stack } from "@mui/material";
import { useState } from "react";
import PropTypes from "prop-types";
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Material Dashboard 2 React example components
// import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
// import DataTable from "examples/Tables/DataTable";
import useAlertDialog from "hooks/useAlertDialog";
// Data
import partsData from "./data";

function ProjectDetails({ editable }) {
  const [edited, setEdited] = useState([]);
  const editability = editable() === "false" ? false : true || true;
  const [handleOpen, AlertDialog] = useAlertDialog();
  const { rows, columns, handleFilter } = partsData(editability, handleOpen);

  const handlEdit = (id) => {
    setEdited(edited.concat(id));
  };

  const handleSave = (id) => {
    setEdited(edited.concat(id));
  };

  return (
    <DashboardLayout>
      <AlertDialog />
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Parts
                </MDTypography>
              </MDBox>
              <MDBox mx={2} mt={3} py={3} px={2}>
                <Stack direction="row" spacing={1}>
                  <Chip
                    label="ALL"
                    color="default"
                    onClick={() => {
                      handleFilter("ALL");
                    }}
                  />
                  <Chip
                    label="SG"
                    color="info"
                    onClick={() => {
                      handleFilter("SG");
                    }}
                  />
                  <Chip
                    label="PG"
                    color="warning"
                    onClick={() => {
                      handleFilter("PG");
                    }}
                  />
                  <Chip
                    label="EDM"
                    color="primary"
                    onClick={() => {
                      handleFilter("EDM");
                    }}
                  />
                  <Chip
                    label="WC"
                    color="success"
                    onClick={() => {
                      handleFilter("WC");
                    }}
                  />
                  <Chip
                    label="CNC"
                    color="secondary"
                    onClick={() => {
                      handleFilter("CNC");
                    }}
                  />
                  <Chip
                    label="Milling"
                    color="error"
                    onClick={() => {
                      handleFilter("MILLING");
                    }}
                  />
                  <Chip
                    label="Lathe"
                    color="error"
                    onClick={() => {
                      handleFilter("LATHE");
                    }}
                  />
                </Stack>
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  table={{ columns, rows }}
                  isSorted
                  entriesPerPage={{
                    defaultValue: 10,
                    entries: [5, 10, 15, 20, 25],
                  }}
                  showTotalEntries
                  noEndBorder={false}
                  canSearch
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

ProjectDetails.defaultProps = {
  editable: () => true,
};

ProjectDetails.propTypes = {
  editable: PropTypes.func,
};

export default ProjectDetails;
