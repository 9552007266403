import algoliaClient from "config/algolia";
import { useDispatch, useSelector } from "react-redux";
import { getCompletedMO, getProjectMOs } from "redux/slices/moSlice";
import { getCompletedProjects } from "redux/slices/projectsSlice";

export default function useSearch() {
  const mos = useSelector((state) => state.mos);
  const projects = useSelector((state) => state.projects);
  const dispatch = useDispatch();
  function searchMOs(text) {
    const index = algoliaClient.initIndex("erp_mos");
    if (text.length > 3) {
      index.search(text).then((results) => {
        const filteredHits = [];
        const { hits } = results;
        hits.forEach((hit) => {
          if (!mos[hit.mo]) {
            filteredHits.push(hit);
          }
        });
        Promise.all(
          filteredHits.map(async (hit) => {
            const { path } = hit;
            dispatch(getCompletedMO(path));
          })
        );
      });
    }
  }

  function searchPOs(text) {
    const index = algoliaClient.initIndex("erp_projects");
    if (text.length > 3) {
      index.search(text).then((results) => {
        const filteredHits = [];
        const { hits } = results;
        hits.forEach((hit) => {
          if (projects.filter((project) => project.ipo === hit.ipo).length === 0) {
            filteredHits.push(hit);
          }
        });
        Promise.all(
          filteredHits.map(async (hit) => {
            const { ipo } = hit;
            dispatch(getCompletedProjects(ipo));
            dispatch(getProjectMOs(ipo));
          })
        );
      });
    }
  }

  return { searchMOs, searchPOs };
}
