import { collection, getCountFromServer, query, where } from "firebase/firestore";
import { db } from "../firebase";

export default async function getClearedPOCount() {
  const thisYear = new Date(Date.now()).getFullYear();
  const thisMonth = new Date(Date.now()).getMonth();
  const lastMonth = thisMonth - 1;
  const colRef = collection(db, "projects");
  const queryThisMonth = query(
    colRef,
    where("completedAt", ">=", new Date(thisYear, thisMonth, 1))
  );
  const countThisMonth = await getCountFromServer(queryThisMonth);
  const queryLastMonth = query(
    colRef,
    where(
      "completedAt",
      ">=",
      new Date(thisMonth === 0 ? thisYear - 1 : thisYear, thisMonth === 0 ? 11 : lastMonth, 1)
    )
  );
  const countLastMonth = await getCountFromServer(queryLastMonth);

  return {
    thisMonth: countThisMonth.data().count,
    lastMonth: countLastMonth.data().count - countThisMonth.data().count,
  };
}
