import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getDocs, collection } from "firebase/firestore";
import machinesConst from "config/machines.json";

import { db } from "../../firebase";

export const machinesSlice = createSlice({
  name: "machines",
  initialState: machinesConst,
  reducers: {},
  /* eslint-disable no-use-before-define */
  /* eslint-disable no-unused-vars */
  /* eslint-disable no-param-reassign */
  /* eslint-disable prefer-destructuring */
  extraReducers(builder) {
    builder.addCase(getMachines.fulfilled, (state, action) => {
      localStorage.setItem("machines", new Date(Date.now()).toTimeString().split(" ")[0]);
      const data = action.payload.data;
      return data;
    });
  },
});

export const getMachines = createAsyncThunk("machines/getMachines", async () => {
  const data = await getDocs(collection(db, "machines")).then((querySnapshot) => {
    const newData = querySnapshot.docs.map((doc) => {
      const originalData = doc.data();
      originalData.key = doc.id;
      try {
        originalData.createdAt = originalData.createdAt.seconds * 1000;
        originalData.updatedAt = originalData.updatedAt.seconds * 1000;
      } catch (e) {
        originalData.createdAt = 1673254226;
        originalData.updatedAt = 1673254226;
      }
      return { ...originalData };
    });
    return newData;
  });
  return { data };
});

export default machinesSlice.reducer;
