/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components

import { Delete } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import { useAddNewJob, useDeleteJob } from "hooks/useJobs";
import AlertDialog from "items/AlertDialog";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Select from "react-select";
import { getJobs } from "redux/slices/jobsSlice";

/* eslint-disable no-unused-vars */

export default function data() {
  const [open, setOpen] = useState({});
  const [loading, setLoading] = useState(false);
  const [process, setProcess] = useState("");
  const [eT, setET] = useState(0);
  const [status, setStatus] = useState("Not Started");

  const pKey = window.location.pathname.split("/")[2];
  const partKey = window.location.pathname.split("/")[3];
  const args = {
    projectKey: pKey,
    partKey,
  };
  const jobs = useSelector((state) => state.jobs);

  const dispatch = useDispatch();
  const addNewJob = useAddNewJob();
  const deleteJob = useDeleteJob();

  useEffect(() => {
    dispatch(getJobs({ projectKey: pKey, partKey }));
  }, [jobs.length]);

  const clearInputs = () => {
    setProcess("");
    setET(0);
    setStatus("Not Started");
  };

  const handleSaveJob = async () => {
    await addNewJob(pKey, partKey, {
      process,
      estimated_time: eT,
      status,
      progress: [],
      reworks: [],
    });
    clearInputs();
  };

  const handleEnter = async (event) => {
    if (event.key === "Enter") {
      await addNewJob(pKey, partKey, {
        process,
        estimated_time: eT,
        status,
        progress: [],
        reworks: [],
      });
      clearInputs();
    }
  };

  const handleDeleteJob = async (jobKey) => {
    setLoading(true);
    await deleteJob(pKey, partKey, jobKey);
    setOpen({ [jobKey]: false });
    setLoading(false);
  };

  const handleProcessChange = (event) => {
    setProcess(event.label);
  };
  const handleETChange = (event) => {
    setET(event.target.value);
  };

  const handleOpenDialog = (k) => {
    setOpen({ [k]: true });
  };

  const handleCloseDialog = (k) => {
    setOpen({ [k]: false });
  };

  const processesOptions = [
    { label: "SG", value: "SG" },
    { label: "PG", value: "PG" },
    { label: "EDM", value: "EDM" },
    { label: "WC", value: "WC" },
    { label: "CNC", value: "CNC" },
    { label: "MANUAL MILL", value: "MANUAL MILL" },
    { label: "LATHE", value: "LATHE" },
  ];

  return {
    args,
    columns: [
      { Header: "Process", accessor: "process", width: "20%", align: "left" },
      { Header: "Estimated Time", accessor: "estimated_time", width: "20%", align: "left" },
      { Header: "Elapsed Time", accessor: "elapsed_time", width: "20%", align: "left" },
      { Header: "Status", accessor: "status", width: "20%", align: "left" },
      { Header: "Actions", accessor: "actions", width: "20%", align: "left" },
    ],
    rows: [
      {
        process: (
          <Select
            placeholder="Select Process"
            options={processesOptions}
            onChange={handleProcessChange}
            styles={{
              menu: () => ({
                background: "white",
              }),
            }}
          />
        ),
        estimated_time: (
          <MDInput
            value={eT}
            type="number"
            onChange={handleETChange}
            onKeyDown={(event) => handleEnter(event)}
          />
        ),
        actions: (
          <MDButton color="info" onClick={handleSaveJob} onKeyDown={handleEnter}>
            Add
          </MDButton>
        ),
      },
      ...jobs.map((job) => ({
        projectKey: pKey,
        partKey,
        process: job.process,
        estimated_time: job.estimated_time,
        elapsed_time: (
          (job.progress || []).reduce((n, { time }) => n + time, 0) /
          (1000 * 60 * 60)
        ).toFixed(2),
        status: job.status,
        actions: (
          <div>
            <IconButton
              onClick={() => {
                handleOpenDialog(job.key);
              }}
            >
              <Delete />
            </IconButton>
            <AlertDialog
              open={open[job.key] || false}
              loading={loading}
              k={job.key || ""}
              handleClose={handleCloseDialog}
              handleAction={handleDeleteJob}
              title="Are you sure to delete?"
              context={`You are going to delete ${job.process}`}
            />
          </div>
        ),
      })),
    ],
  };
}
