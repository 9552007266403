import { useDispatch, useSelector } from "react-redux";
import { getCustomers } from "redux/slices/customersSlice";
import { getmachinesPerformances } from "redux/slices/machinesPerformancesSlice";
import { getMachines } from "redux/slices/machinesSlice";
import { getCompletedMOs, getMOs } from "redux/slices/moSlice";
import { getprojects } from "redux/slices/projectsSlice";
import { getWorkersPerformances } from "redux/slices/workersPerformancesSlice";
import { getPPs, getWorkers } from "redux/slices/workersSlice";

export default function useFetchData() {
  const currentState = useSelector((state) => state);
  const dispatch = useDispatch();
  function dispacthData() {
    console.info("Fetching Data at", new Date(Date.now()).toISOString().split("T")[0]);
    dispatch(getprojects());
    dispatch(getCustomers());
    dispatch(getWorkers());
    dispatch(getPPs(currentState.workers));
    dispatch(getMOs());
    dispatch(getMachines());
    dispatch(getWorkersPerformances());
    dispatch(getmachinesPerformances());
  }

  function dispatchCompletedMOs() {
    console.info("Fetching Completed MOs at", new Date(Date.now()).toISOString().split("T")[0]);
    dispatch(getCompletedMOs());
  }

  return [dispacthData, dispatchCompletedMOs];
}
