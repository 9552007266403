import { doc, setDoc, updateDoc, arrayUnion, deleteDoc } from "firebase/firestore";
import { useDispatch } from "react-redux";
import { getParts } from "redux/slices/partsSlice";
import { db } from "../firebase";

export const useAddNewPart = () => {
  const dispatch = useDispatch();

  async function addNewPart(projKey, data) {
    let docRef = doc(db, "projects", projKey, "parts", data.mo);
    const docData = await setDoc(docRef, {
      ...data,
      ...{ createdAt: new Date(Date.now()), updatedAt: new Date(Date.now()) },
    });

    docRef = doc(db, "parts", data.item_name);
    const newData = {
      item_name: data.item_name,
      material: data.material,
      mo_data: {
        mo: data.mo,
        estimated_time: 0,
        elapsed_time: 0,
        quantity: data.quantity,
        createdAt: new Date(Date.now()),
        updatedAt: new Date(Date.now()),
      },
    };
    try {
      await updateDoc(docRef, {
        mo_data: arrayUnion(newData.mo_data),
        updatedAt: new Date(Date.now()),
      });
    } catch (e) {
      newData.mo_data = [newData.mo_data];
      await setDoc(docRef, newData);
    }
    dispatch(getParts(projKey));
    return docData;
  }

  return addNewPart;
};

export const useEditPart = () => {
  const dispatch = useDispatch();

  async function editPart(data, projKey, partKey) {
    const docRef = doc(db, "projects", projKey, "parts", partKey);
    const uDoc = await updateDoc(docRef, { ...data, ...{ updatedAt: new Date(Date.now()) } });
    dispatch(getParts(projKey));
    return uDoc;
  }

  return editPart;
};

export const useDeletePart = () => {
  const dispatch = useDispatch();

  async function deletePart(projectKey, partKey) {
    const docRef = doc(db, "projects", projectKey, "parts", partKey);
    await deleteDoc(docRef);
    dispatch(getParts(projectKey));
  }

  return deletePart;
};
