import MDBox from "components/MDBox";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";

export default function TotalScrapTime() {
  return (
    <MDBox mb={1.5}>
      <ComplexStatisticsCard
        color="primary"
        icon="error"
        title="Total Scrap Time"
        count="+9"
        percentage={{
          color: "error",
          amount: "+5%",
          label: "than Last Month",
        }}
      />
    </MDBox>
  );
}
