const verticalBarChartData = {
    labels: [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31],
    datasets: [
      {
        label: "Operated",
        color: "dark",
        data: [10,11,8,9,15,14.5,13,11,12,15,15.5,13,11,12],
      },
    ],
  };

  const pieChartData = {
    labels: ["Operated", "Idle"],
    datasets: {
      label: "Machine runtime",
      backgroundColors: ["info", "dark"],
      data: [200,80],
    },
  };
  
  export {verticalBarChartData,pieChartData};