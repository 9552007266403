/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import { Stack } from "@mui/material";
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React examples
import DefaultItem from "examples/Items/DefaultItem";
import ongoingMoData from "../data/ongoingMOData";

function OngoingMos() {
  const ongoingMos = ongoingMoData();
  return (
    <Card sx={{ height: "100%" }}>
      <MDBox pt={2} px={2} lineHeight={1}>
        <MDTypography variant="h6" fontWeight="medium">
          Ongoing MOs
        </MDTypography>
      </MDBox>
      <Stack direction="row">
        <MDBox p={2} key={0}>
          {ongoingMos.splice(0, Math.round(ongoingMos.length / 2)).map((mo) => (
            <MDBox mt={2.5} key={`${mo.mo}-${mo.worker}`}>
              <DefaultItem
                color="info"
                icon="assignment"
                title={`MO Number: ${mo.mo}`}
                description={`Handled by: ${mo.worker} at ${mo.dep}`}
              />
            </MDBox>
          ))}
        </MDBox>
        <MDBox p={2} key={1}>
          {ongoingMos.map((mo) => (
            <MDBox mt={2.5} key={`${mo.mo}-${mo.worker}`}>
              <DefaultItem
                color="info"
                icon="assignment"
                title={`MO Number: ${mo.mo}`}
                description={`Handled by: ${mo.worker} at ${mo.dep}`}
              />
            </MDBox>
          ))}
        </MDBox>
      </Stack>
    </Card>
  );
}

export default OngoingMos;
