/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-unused-vars */
import { useState } from "react";

// Material Dashboard 2 React components
import MDSnackbar from "components/MDSnackbar";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";


// Child Components
import NewCustomer from "./newCustomer";
import NewProject from "./newProject";
import NewWorker from "./newWorker";

function New() {
  const [show, setShow] = useState(false);
  const [sbTitle,setSbTitle] = useState("");
  const [sbContent,setSbContent] = useState("");
  const [sbColor,setSbColor] = useState("success");

  
  const toggleSnackbar = () => setShow(!show);
  const handleStatus = (title,content,color) => {
    setSbTitle(title);
    setSbContent(content);
    setSbColor(color);
    toggleSnackbar();
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
        <NewProject 
          handleStatus={handleStatus}
        />
        <NewCustomer
          handleStatus={handleStatus}
        />
        <NewWorker
          handleStatus={handleStatus}
        />
        <MDSnackbar
          color={sbColor}
          icon="notifications"
          title={sbTitle}
          content={sbContent}
          dateTime="now"
          open={show}
          close={toggleSnackbar}
        />
      <Footer />
    </DashboardLayout>
  );
}

export default New;
