/* eslint-disable react/function-component-definition */
/* eslint-disable arrow-body-style */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/button-has-type */
/* eslint-disable no-unused-vars */
/* eslint-disable react/self-closing-comp */

import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import MDBox from "components/MDBox";

import { useSelector, useDispatch } from "react-redux";

import workersPerformancesData from "layouts/dashboard/data/workersPerformancesData";
import workersEffectiveHoursData from "layouts/dashboard/data/workersEffectiveHoursData";
import { Box, Card, Grid, Paper, Stack, Typography } from "@mui/material";
import PageLayout from "examples/LayoutContainers/PageLayout";
import useGetMachinesPerformances from "hooks/useGetMachinesPerformances";
import MDAvatar from "components/MDAvatar";
import { getPPs } from "redux/slices/workersSlice";
import { getMachines } from "redux/slices/machinesSlice";

import "./index.css";
import CNCM from "layouts/machines/components/c-n-c-m";
import PG from "layouts/machines/components/p-g";
import EDM from "layouts/machines/components/e-d-m";
import SG from "layouts/machines/components/s-g";
import Lathe from "layouts/machines/components/lathe";
import Mill from "layouts/machines/components/mill";
import Press from "layouts/machines/components/press";

const WorkshopLayoutDisplay = ({ footer, machines, workingMachines, percs, workers }) => {
  const getDetails = (dep, dir) => (
    <Stack direction={dir} spacing={1}>
      {workingMachines
        .filter((machine) => machine.department === dep)
        .map((machine) => (
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              "& > :not(style)": {
                m: 1,
                width: 120,
                height: 105,
              },
            }}
          >
            <Paper
              elevation={5}
              sx={{
                color: "#ffffff",
                background: () => {
                  let color = "#1A73E8";
                  if (machine.running) {
                    color = "#4CAF50";
                  }
                  if (!machine.working) {
                    color = "#F44335";
                  }
                  if (machine.partoff) {
                    color = "#F4D03F";
                  }
                  return color;
                },
                textAlign: "center",
              }}
            >
              {" "}
              <MDAvatar
                sx={{ m: "auto" }}
                src={
                  Number(machine.worker)
                    ? workers.filter((worker) => worker.id === machine.worker)[0].pp
                    : "N/A"
                }
              />
              <Stack>
                <Typography variant="caption" fontWeight={700}>
                  {machine.name} : {machine.assigned_work}
                </Typography>
                <Typography variant="caption" fontWeight={700}>
                  {Number(machine.worker)
                    ? workers.filter((worker) => worker.id === machine.worker)[0].name.split(" ")[0]
                    : "N/A"}
                </Typography>
                <Typography variant="caption" fontWeight={700}>
                  {percs[machine.key]}%
                </Typography>
              </Stack>
            </Paper>
          </Box>
        ))}
    </Stack>
  );

  return (
    <Box>
      <Stack alignItems="center">
        <Grid item>{getDetails("SG", "row")}</Grid>
        <Grid justifyContent="center" container spacing={1}>
          <Grid item>{getDetails("PG", "column")}</Grid>
          <Grid item>{getDetails("WC", "column")}</Grid>
          <Grid item key={6}>
            <MDBox pt={6} pb={3}>
              {machines.length === 32 ? (
                <MDBox pt={6}>
                  <div className="workshop-layout-container">
                    <div className="workshop-layout-container01">
                      <Box pb={40}></Box>
                      <div className="workshop-layout-container02">
                        <div className="workshop-layout-container03">
                          <CNCM
                            machines={machines}
                            workers={workers}
                            rootClassName="c-n-c-m-root-class-name"
                          ></CNCM>
                        </div>
                      </div>
                      <div className="workshop-layout-container05">
                        <PG
                          machines={machines}
                          workers={workers}
                          rootClassName="p-g-root-class-name"
                        ></PG>
                        <div className="workshop-layout-container07">
                          <EDM
                            machines={machines}
                            workers={workers}
                            rootClassName="e-d-m-root-class-name"
                          ></EDM>
                        </div>
                      </div>
                      <div className="workshop-layout-container08">
                        <div className="workshop-layout-container09">
                          <SG
                            machines={machines}
                            workers={workers}
                            rootClassName="s-g-root-class-name"
                          ></SG>
                        </div>
                        <div className="workshop-layout-container10">
                          <Lathe
                            machines={machines}
                            workers={workers}
                            rootClassName="lathe-root-class-name"
                          ></Lathe>
                        </div>
                      </div>
                      <div className="workshop-layout-container11">
                        <div className="workshop-layout-container12">
                          <Mill
                            machines={machines}
                            workers={workers}
                            rootClassName="mill-root-class-name"
                          ></Mill>
                        </div>
                        <div className="workshop-layout-container13">
                          <Press rootClassName="press-root-class-name"></Press>
                        </div>
                      </div>
                    </div>
                  </div>
                </MDBox>
              ) : (
                <div />
              )}
            </MDBox>
          </Grid>
          <Grid item>{getDetails("EDM", "column")}</Grid>
          <Grid item>{getDetails("CNC", "column")}</Grid>
        </Grid>
        <Typography>{footer}</Typography>
      </Stack>
    </Box>
  );
};

WorkshopLayoutDisplay.propTypes = {
  footer: PropTypes.string.isRequired,
  machines: PropTypes.arrayOf(PropTypes.any).isRequired,
  workers: PropTypes.arrayOf(PropTypes.any).isRequired,
  workingMachines: PropTypes.arrayOf(PropTypes.any).isRequired,
  percs: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default WorkshopLayoutDisplay;
