import { getTotalHoursPerMonth } from "utils/getMonthlyTotalHours";

const { useSelector } = require("react-redux");

const useGetMachinesPerformances = (machines) => {
  const percentages = {};

  const machinesPerformances = useSelector((state) => state.machinesPerformances);

  machines.forEach((machine) => {
    percentages[machine.key] = 0;
    const thisYear = new Date(Date.now()).getFullYear();
    const thisMonth = new Date(Date.now()).getMonth();
    const totalHours = getTotalHoursPerMonth(thisMonth, thisYear);
    const selectedMachine = machinesPerformances.filter(
      (_machine) =>
        _machine.machine === machine.key &&
        new Date(_machine.date).getFullYear() === thisYear &&
        new Date(_machine.date).getMonth() === thisMonth
    );

    selectedMachine.forEach((data) => {
      // if (!perc[data.date]) perc[data.date] = data.consumed_time * (100 / (7.75 + 6.5));
      // else perc[data.date] += data.consumed_time * (100 / (7.75 + 6.5));
      percentages[machine.key] += (data.consumed_time / totalHours) * 100;
    });
    percentages[machine.key] = Number(percentages[machine.key]).toFixed(2);
  });
  return percentages;
};

export default useGetMachinesPerformances;
