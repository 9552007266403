import useGetWorkersPerformances from "hooks/useGetWorkersPerformances";
import { useSelector } from "react-redux";

export default function workersPerformancesData() {
  const workers = useSelector((state) => state.workers);
  const performances = useGetWorkersPerformances(workers);
  const wData = {
    labels: [],
    datasets: {
      label: "Performances %",
      data: [],
    },
  };

  const percsArray = Object.keys(performances).sort((a, b) => performances[b] - performances[a]);
  percsArray.slice(0, 5).forEach((key) => {
    if (performances[key] > 0) {
      wData.labels.push(workers.filter((worker) => worker.key === key)[0].name);
      wData.datasets.data.push(performances[key]);
    }
  });

  return wData;
}
