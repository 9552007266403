import { collection, addDoc, deleteDoc, doc } from "firebase/firestore";
import { useDispatch } from "react-redux";
import { getCustomers } from "redux/slices/customersSlice";
import { db } from "../firebase";

export const useAddNewCustomer = () => {
  const dispatch = useDispatch();

  async function addNewCustomer(data) {
    const dbRef = collection(db, "customers");
    await addDoc(dbRef, {
      ...data,
      ...{ createdAt: new Date(Date.now()), updatedAt: new Date(Date.now()) },
    });
    dispatch(getCustomers());
  }

  return addNewCustomer;
};

export const useDeleteCustomer = () => {
  const dispatch = useDispatch();

  async function deleteCustomer(key) {
    const docRef = doc(db, "customers", key);
    await deleteDoc(docRef);
    dispatch(getCustomers());
  }

  return deleteCustomer;
};
