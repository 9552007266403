import MDBox from "components/MDBox";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";
import clearedPOsData from "../data/clearedPOsData";

export default function POsCleared() {
  const cPOData = clearedPOsData();

  return (
    <MDBox mb={1.5}>
      <ComplexStatisticsCard
        icon="leaderboard"
        title="POs Cleared"
        count={cPOData.count}
        percentage={{
          color: cPOData.percentage > 0 ? "success" : "error",
          amount: `${cPOData.percentage}%`,
          label: "than Last Month",
        }}
      />
    </MDBox>
  );
}
