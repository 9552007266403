/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
/* eslint-disable no-unused-vars */
import {
  DataGrid,
  GridCellEditStopParams,
  GridCellEditStopReasons,
  MuiEvent,
  GridToolbar,
} from "@mui/x-data-grid";

import { Chip, IconButton, Stack } from "@mui/material";
import { useState } from "react";
import PropTypes from "prop-types";
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Material Dashboard 2 React example components
// import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import Footer from "examples/Footer";
// import DataTable from "examples/Tables/DataTable";

import { getMOs } from "redux/slices/moSlice";
import { Refresh } from "@mui/icons-material";

import { useDispatch } from "react-redux";

// Data
import partsData from "./data";

function Progress() {
  const dispatch = useDispatch();

  const { rows, columns, handleFilter } = partsData();

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  MO Progress
                </MDTypography>
              </MDBox>
              <MDBox mx={2} mt={3} py={3} px={2}>
                <Stack direction="row" spacing={1}>
                  <Chip
                    label="ALL"
                    color="default"
                    onClick={() => {
                      handleFilter("ALL");
                    }}
                  />
                  <Chip
                    label="SG"
                    color="info"
                    onClick={() => {
                      handleFilter("SG");
                    }}
                  />
                  <Chip
                    label="PG"
                    color="warning"
                    onClick={() => {
                      handleFilter("PG");
                    }}
                  />
                  <Chip
                    label="EDM"
                    color="primary"
                    onClick={() => {
                      handleFilter("EDM");
                    }}
                  />
                  <Chip
                    label="WC"
                    color="success"
                    onClick={() => {
                      handleFilter("WC");
                    }}
                  />
                  <Chip
                    label="CNC"
                    color="secondary"
                    onClick={() => {
                      handleFilter("CNC");
                    }}
                  />
                  <Chip
                    label="Milling"
                    color="error"
                    onClick={() => {
                      handleFilter("MILLING");
                    }}
                  />
                  <Chip
                    label="Lathe"
                    color="error"
                    onClick={() => {
                      handleFilter("LATHE");
                    }}
                  />
                  <IconButton
                    onClick={() => {
                      dispatch(getMOs());
                    }}
                  >
                    <Refresh />
                  </IconButton>
                </Stack>
              </MDBox>
              <MDBox mx={2} mt={3} py={3} px={2}>
                <Grid container rowSpacing={5} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                  <DataTable
                    table={{ columns, rows }}
                    isSorted
                    entriesPerPage={{
                      defaultValue: 10,
                      entries: [5, 10, 15, 20, 25],
                    }}
                    showTotalEntries
                    noEndBorder={false}
                    canSearch
                  />
                </Grid>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Progress;
