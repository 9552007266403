/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/* eslint-disable react/no-danger */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import { Delete, ArrowCircleRight, Done, RemoveDone } from "@mui/icons-material";
import { Grid, IconButton } from "@mui/material";

import { useState, useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import { useDeleteProject, useCompleteProject, useReplaceProject } from "hooks/useProjects";

export default function data(_name, openDialog) {
  const name = _name() || "all";
  const [projects, setProjects] = useState([]);
  const prj = useSelector((state) => state.projects);
  const deleteProject = useDeleteProject();
  const completeProject = useCompleteProject();
  const replaceProject = useReplaceProject();
  const navigate = useNavigate();

  useMemo(() => {
    if (name === "all") {
      setProjects(prj);
    } else {
      setProjects(prj.filter((p) => p.customer === name));
    }
  }, [prj]);

  const handleDelete = useCallback(async (projectKey) => {
    await deleteProject(projectKey);
  });

  const handleComplete = useCallback(async (projectKey) => {
    await completeProject(projectKey);
  });
  const handleReplace = useCallback(async (projectKey) => {
    await replaceProject(projectKey);
  });

  const handleGoto = (path) => {
    navigate(path);
  };

  return {
    columns: [
      { Header: "IPO", accessor: "ipo", align: "left" },
      { Header: "Customer", accessor: "customer", align: "left" },
      { Header: "Parts Count", accessor: "count", align: "center" },
      { Header: "Estimated Price", accessor: "price", align: "center" },
      { Header: "Cost", accessor: "cost", align: "center" },
      { Header: "Profitability", accessor: "profitability", align: "center" },
      {
        Header: "Delivery Date",
        accessor: "ed",
        align: "center",
        sortType: "datetime",
        Cell: ({ cell: { value } }) => value.toLocaleDateString("en-UK"),
      },
      { Header: "Customer PO", accessor: "cpo", align: "left" },
      { Header: "Issued By", accessor: "issued", align: "left" },
      { Header: "Remarks", accessor: "remarks", align: "left" },
      { Header: "Customize", accessor: "customize", align: "center" },
    ],

    rows: projects.map((project) => ({
      ipo: project.ipo,
      customer: project.customer || "N/A",
      count: project.parts_count || 0,
      price: `S$  ${project.price || 0}`,
      cost: `S$  ${Number(project.cost || 0).toFixed(2)}`,
      profitability: `${(((project.price || 0) / (project.cost || project.price)) * 100).toFixed(
        2
      )} %`,
      ed: new Date(project.estimated_delivery),
      cpo: project.cpo || "N/A",
      issued: project.issued_by || "N/A",
      remarks: <div dangerouslySetInnerHTML={{ __html: project.remarks }} />,
      customize: (
        <div>
          {name === "all" ? (
            <Grid container spacing={2}>
              <Grid item xs={4} key={0}>
                <IconButton onClick={() => handleGoto(`/projects/${project.key}`)}>
                  <ArrowCircleRight />
                </IconButton>
              </Grid>
              <Grid item xs={4} key={1}>
                <IconButton
                  onClick={() => {
                    openDialog({
                      k: project.key,
                      handleAction: handleDelete,
                      title: "Are you sure to delete?",
                      context: `You are going to delete ${project.ipo || "N/A"}`,
                    });
                  }}
                >
                  <Delete />
                </IconButton>
              </Grid>
              {!project.completed ? (
                <Grid item xs={4} key={2}>
                  <IconButton
                    onClick={() => {
                      openDialog({
                        k: project.key,
                        handleAction: handleComplete,
                        title: "Are you sure to complete?",
                        context: `You are going to complete ${project.ipo || "N/A"}`,
                      });
                    }}
                  >
                    <Done />
                  </IconButton>
                </Grid>
              ) : (
                <Grid item xs={4} key={2}>
                  <IconButton
                    onClick={() => {
                      openDialog({
                        k: project.key,
                        handleAction: handleReplace,
                        title: "Are you sure to replace?",
                        context: `You are going to replace ${project.ipo || "N/A"}`,
                      });
                    }}
                  >
                    <RemoveDone />
                  </IconButton>
                </Grid>
              )}
            </Grid>
          ) : (
            <IconButton onClick={() => handleGoto(`/projects/${project.key}?editable=false`)}>
              <ArrowCircleRight />
            </IconButton>
          )}
        </div>
      ),
    })),
  };
}
