/* eslint-disable react/function-component-definition */
/* eslint-disable arrow-body-style */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/button-has-type */
/* eslint-disable no-unused-vars */
/* eslint-disable react/self-closing-comp */

import React from "react";

import PropTypes from "prop-types";

import "./e-d-m.css";
import MDButton from "components/MDButton";
import MDBox from "components/MDBox";
import { Stack, Tooltip, Typography } from "@mui/material";
import useGetMachinesPerformances from "hooks/useGetMachinesPerformances";

const EDM = (props) => {
  const { machines, workers } = props;
  const edm = machines.filter((machine) => machine.department === "EDM");
  const wc = machines.filter((machine) => machine.department === "WC");
  const percsEDM = useGetMachinesPerformances(edm);
  const percsWC = useGetMachinesPerformances(wc);
  const percs = { ...percsEDM, ...percsWC };

  const data = {
    button1: edm.filter((machine) => machine.key === "edm1")[0],
    button: edm.filter((machine) => machine.key === "edm2")[0],
    button4: wc.filter((machine) => machine.key === "wc1")[0],
    button3: wc.filter((machine) => machine.key === "wc2")[0],
    button2: wc.filter((machine) => machine.key === "wc3")[0],
    button5: wc.filter((machine) => machine.key === "sd")[0],
  };
  const getColor = (btn) => {
    if (btn.running) {
      return "success";
    }
    if (btn.partoff) {
      return "partoff";
    }
    if (btn.working) {
      return "info";
    }

    return "error";
  };

  const getMessage = (_button) => {
    let msg = "";
    try {
      const matches = workers.filter((w) => parseInt(w.id, 10) === parseInt(_button.worker, 10));
      msg = (
        <Stack>
          <Typography variant="body">Machine: {_button.name}</Typography>
          <Typography variant="body">Job: {_button.assigned_work}</Typography>
          <Typography variant="body">
            Operator:{" "}
            {_button.worker === "N/A" || !_button.worker ? _button.worker : matches[0].name}
          </Typography>
          <Typography variant="body">Utilization: {percs[_button.key] || 0}%</Typography>
        </Stack>
      );
    } catch (e) {
      msg = "Loading...";
    }
    return msg;
  };
  return (
    <div>
      <MDBox mt={5} mb={-2} ml={30}>
        <Tooltip title={getMessage(data.button5)}>
          <MDButton color={getColor(data.button5)} size="small">
            {props.button5}
          </MDButton>
        </Tooltip>
      </MDBox>
      <div className={`e-d-m-container ${props.rootClassName} `}>
        <Tooltip title={getMessage(data.button)}>
          <MDBox mx={1}>
            <MDButton color={getColor(data.button)} size="small">
              {props.button}
            </MDButton>
          </MDBox>
        </Tooltip>
        <Tooltip title={getMessage(data.button1)}>
          <MDBox mx={1}>
            <MDButton color={getColor(data.button1)} size="small">
              {props.button1}
            </MDButton>
          </MDBox>
        </Tooltip>
        <Tooltip title={getMessage(data.button2)}>
          <MDBox mx={1}>
            <MDButton color={getColor(data.button2)} size="small">
              {props.button2}
            </MDButton>
          </MDBox>
        </Tooltip>
        <Tooltip title={getMessage(data.button3)}>
          <MDBox mx={1}>
            <MDButton color={getColor(data.button3)} size="small">
              {props.button3}
            </MDButton>
          </MDBox>
        </Tooltip>
        <Tooltip title={getMessage(data.button4)}>
          <MDButton color={getColor(data.button4)} size="small">
            {props.button4}
          </MDButton>
        </Tooltip>
      </div>
    </div>
  );
};

EDM.defaultProps = {
  button: "EDM02",
  button1: "EDM01",
  button2: "WC03",
  button3: "WC02",
  button4: "WC01",
  button5: "SD",
  rootClassName: "",
};

EDM.propTypes = {
  button: PropTypes.string,
  button1: PropTypes.string,
  button2: PropTypes.string,
  button3: PropTypes.string,
  button4: PropTypes.string,
  button5: PropTypes.string,
  rootClassName: PropTypes.string,
  machines: PropTypes.arrayOf(PropTypes.any).isRequired,
  workers: PropTypes.arrayOf(PropTypes.any).isRequired,
};

export default EDM;
