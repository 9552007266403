import { collection, addDoc, doc, deleteDoc, updateDoc } from "firebase/firestore";
import { useDispatch } from "react-redux";
import { getprojects } from "redux/slices/projectsSlice";
import { db } from "../firebase";

export const useAddNewProject = () => {
  const dispatch = useDispatch();

  async function addNewProject(data) {
    const dbRef = collection(db, "projects");
    if (data.customer !== "" && data.id !== 0 && data.parts_count !== 0) {
      await addDoc(dbRef, {
        ...data,
        ...{ createdAt: new Date(Date.now()), updatedAt: new Date(Date.now()) },
      });
      dispatch(getprojects());
      return {};
    }

    throw new Error("invalid data");
  }

  return addNewProject;
};

export const useDeleteProject = () => {
  const dispatch = useDispatch();

  async function deleteProject(key) {
    const docRef = doc(db, "projects", key);
    await deleteDoc(docRef);
    dispatch(getprojects());
  }

  return deleteProject;
};

export const useCompleteProject = () => {
  const dispatch = useDispatch();

  async function completeProject(key) {
    const docRef = doc(db, "projects", key);
    await updateDoc(docRef, {
      completed: true,
      completedAt: new Date(Date.now()),
    });
    dispatch(getprojects());
  }

  return completeProject;
};

export const useReplaceProject = () => {
  const dispatch = useDispatch();

  async function replaceProject(key) {
    const docRef = doc(db, "projects", key);
    await updateDoc(docRef, {
      completed: false,
    });
    dispatch(getprojects());
  }

  return replaceProject;
};

export const useUpdateProject = () => {
  const dispatch = useDispatch();

  async function updateProject(key, data) {
    const docRef = doc(db, "projects", key);
    await updateDoc(docRef, data);
    dispatch(getprojects());
  }

  return updateProject;
};
