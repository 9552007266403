/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-curly-brace-presence */

// Material Dashboard 2 React example components
import PropTypes from "prop-types";
import { AgGridReact } from "ag-grid-react";

import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-alpine.css"; // Optional theme CSS
import { useCallback, useRef } from "react";
import MDButton from "components/MDButton";
import MDBox from "components/MDBox";

function WorkerPerformance({ data }) {
  const gridRef = useRef();
  const onBtnExport = useCallback(() => {
    gridRef.current.api.exportDataAsCsv({ fileName: `${data[0]?.worker}_${Date.now()}` });
  }, []);
  const headers = [
    { field: "date" },
    { field: "mo" },
    { field: "operator" },
    { field: "estimated time" },
    { field: "consumed time" },
    { field: "operator performance job" },
  ];

  return (
    <div>
      <div className="ag-theme-alpine" style={{ width: 1200, height: 500 }}>
        <AgGridReact columnDefs={headers} rowData={data} ref={gridRef} />
      </div>
      <MDBox pt={1} pb={1}>
        <MDButton onClick={onBtnExport} color="info">
          Download Report
        </MDButton>
      </MDBox>
    </div>
  );
}

WorkerPerformance.propTypes = {
  data: PropTypes.arrayOf(PropTypes.any).isRequired,
};

export default WorkerPerformance;
