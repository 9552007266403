import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getDocs, collection, query, where } from "firebase/firestore";
import { db } from "../../firebase";

export const projectsSlice = createSlice({
  name: "projects",
  initialState: [],
  reducers: {},
  /* eslint-disable no-use-before-define */
  /* eslint-disable no-unused-vars */
  /* eslint-disable no-param-reassign */
  /* eslint-disable prefer-destructuring */
  extraReducers(builder) {
    builder.addCase(getprojects.fulfilled, (state, action) => {
      localStorage.setItem("projects", new Date(Date.now()).toTimeString().split(" ")[0]);
      const data = action.payload.data;
      return data;
    });
    builder.addCase(getCompletedProjects.fulfilled, (state, action) => {
      localStorage.setItem("projects", new Date(Date.now()).toTimeString().split(" ")[0]);
      const data = action.payload.data;
      return [...state, ...data];
    });
    builder.addCase(getProjectParts.fulfilled, (state, action) => {
      localStorage.setItem("projects", new Date(Date.now()).toTimeString().split(" ")[0]);
      const data = action.payload.data;
      const project = action.payload.project;
      const index = state.findIndex((prj) => prj.key === project.key);
      state[index].parts = data;
    });
  },
});

export const getprojects = createAsyncThunk("projects/getprojects", async () => {
  const data = await getDocs(
    query(collection(db, "projects"), where("completed", "!=", true))
  ).then((querySnapshot) => {
    const newData = querySnapshot.docs.map((doc) => {
      const originalData = doc.data();
      originalData.key = doc.id;
      originalData.estimated_delivery = originalData.estimated_delivery.seconds * 1000;
      originalData.parts = [];
      try {
        originalData.createdAt = originalData.createdAt.seconds * 1000;
        originalData.updatedAt = originalData.updatedAt.seconds * 1000;
      } catch (e) {
        originalData.createdAt = 1673254226;
        originalData.updatedAt = 1673254226;
      }
      try {
        originalData.completedAt = originalData.completedAt.seconds * 1000;
      } catch (e) {
        originalData.completedAt = 1673254226;
      }
      return { ...originalData };
    });
    return newData;
  });
  return { data };
});

export const getCompletedProjects = createAsyncThunk(
  "projects/getCompletedProjects",
  async (ipo) => {
    const data = await getDocs(query(collection(db, "projects"), where("ipo", "==", ipo))).then(
      (querySnapshot) => {
        const newData = querySnapshot.docs.map((doc) => {
          const originalData = doc.data();
          originalData.key = doc.id;
          originalData.estimated_delivery = originalData.estimated_delivery.seconds * 1000;
          originalData.parts = [];
          try {
            originalData.createdAt = originalData.createdAt.seconds * 1000;
            originalData.updatedAt = originalData.updatedAt.seconds * 1000;
          } catch (e) {
            originalData.createdAt = 1673254226;
            originalData.updatedAt = 1673254226;
          }
          try {
            originalData.completedAt = originalData.completedAt.seconds * 1000;
          } catch (e) {
            originalData.completedAt = 1673254226;
          }
          return { ...originalData };
        });
        return newData;
      }
    );
    return { data };
  }
);

export const getProjectParts = createAsyncThunk("projects/getparts", async (project) => {
  const data = await getDocs(collection(db, "projects", project.key, "parts")).then(
    (querySnapshot) => {
      const newData = querySnapshot.docs.map((doc) => {
        const originalData = doc.data();
        originalData.key = doc.id;
        originalData.projectKey = project.key;
        return { ...originalData };
      });
      return newData;
    }
  );
  return { data, project };
});

export default projectsSlice.reducer;
