import { useMemo, useState } from "react";
import getClearedPOCount from "utils/getClearedPOCount";

export default function clearedPOsData() {
  const [counts, setCounts] = useState({ thisMonth: 0, lastMonth: 0 });
  useMemo(async () => {
    const c = await getClearedPOCount();
    setCounts(c);
  }, []);

  return {
    count: counts.thisMonth,
    percentage: (((counts.thisMonth - counts.lastMonth) / (counts.thisMonth || 1)) * 100).toFixed(
      2
    ),
  };
}
