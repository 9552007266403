/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components

import { AccountTree, Launch } from "@mui/icons-material";
import { Grid, IconButton } from "@mui/material";
import CircularProgressWithLabel from "items/CircularProgressWithLabel";
import { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import getTimes from "utils/getTimes";

export default function data() {
  const mosObj = useSelector((state) => state.mos);
  const mos = [];
  Object.keys(mosObj).forEach((key) => {
    mos.push(mosObj[key]);
  });
  const [progress, setProgress] = useState();
  const [timesData, setTimesData] = useState();

  const [filter, setFilter] = useState("ALL");
  const [filteredMos, setFilteredMos] = useState([]);

  useMemo(() => {
    if (progress) {
      const notCompleted = mos.filter((mo) => mo.status === "Not Started");
      const fm = notCompleted.filter((mo) => progress[mo.key][filter] >= 0);
      setFilteredMos(fm);
      if (filter === "ALL") {
        setFilteredMos(notCompleted);
      }
    }
  }, [filter, progress]);

  useMemo(() => {
    Promise.all(
      mos.map(async (part) => {
        const procData = {
          status: {},
        };
        const { jobs } = mos.filter((mo) => mo.key === part.key)[0];
        if (jobs.length === 0) {
          procData.SG = 0;
          procData.status.SG = "Not Started";
        } else {
          Object.keys(jobs).forEach((key) => {
            procData[jobs[key].process] = (jobs[key].progress || []).reduce(
              (n, { percentage }) => n + percentage,
              0
            );
            procData.status[jobs[key].process] = jobs[key].status;
          });
        }
        return { [part.key]: procData };
      })
    ).then((percs) => {
      let temp = {};
      percs.forEach((perc) => {
        temp = { ...temp, ...perc };
      });
      setProgress(temp);
    });
  }, [mos.length]);

  useMemo(() => {
    const tempTimes = {};
    mos.forEach((mo) => {
      tempTimes[mo.key] = getTimes(mo);
    });
    setTimesData(tempTimes);
  }, [mos.length]);

  const navigate = useNavigate();

  const goTo = (path) => {
    navigate(path);
  };

  const handleFilter = (f) => {
    setFilter(f);
  };

  return {
    columns: [
      { Header: "MO", accessor: "mo", width: "20%", align: "left" },
      { Header: "Item Name", accessor: "name", width: "20%", align: "left" },
      { Header: "Status", accessor: "status", align: "left" },
      { Header: "Estimated Time", accessor: "et", align: "left" },
      { Header: "Elapsed Time", accessor: "elt", align: "center" },
      {
        Header: "Delivery Date",
        accessor: "delivery",
        align: "center",
        sortType: "datetime",
        Cell: ({ cell: { value } }) => value.toLocaleDateString("en-UK"),
      },
      { Header: "Details", accessor: "details", align: "center" },
      { Header: "Project", accessor: "project", align: "center" },
    ],
    rows: filteredMos.map((mo) => ({
      mo: mo.mo || "N/A",
      name: mo.item_name || "N/A",
      status: !progress[mo.key] ? (
        <Grid container spacing={0.2}>
          <Grid item key="sg">
            <CircularProgressWithLabel variant="indeterminate" color="info" value={0} />
          </Grid>
          <Grid item key="pg">
            <CircularProgressWithLabel variant="indeterminate" color="warning" value={0} />
          </Grid>
          <Grid item key="edm">
            <CircularProgressWithLabel variant="indeterminate" color="primary" value={0} />
          </Grid>
          <Grid item key="wc">
            <CircularProgressWithLabel variant="indeterminate" color="success" value={0} />
          </Grid>
          <Grid item key="cnc">
            <CircularProgressWithLabel variant="indeterminate" color="secondary" value={0} />
          </Grid>
          <Grid item key="mm">
            <CircularProgressWithLabel variant="indeterminate" color="error" value={0} />
          </Grid>
          <Grid item key="lathe">
            <CircularProgressWithLabel variant="indeterminate" color="error" value={0} />
          </Grid>
        </Grid>
      ) : (
        <Grid container spacing={0.2}>
          <Grid item key="sg">
            {progress[mo.key].SG >= 0 ? (
              <CircularProgressWithLabel
                variant={progress[mo.key].status.SG === "Started" ? "indeterminate" : "determinate"}
                color="info"
                value={progress[mo.key].SG ? progress[mo.key].SG : 0}
              />
            ) : (
              <div />
            )}
          </Grid>
          <Grid item key="pg">
            {progress[mo.key].PG >= 0 ? (
              <CircularProgressWithLabel
                variant={progress[mo.key].status.PG === "Started" ? "indeterminate" : "determinate"}
                color="warning"
                value={progress[mo.key].PG ? progress[mo.key].PG : 0}
              />
            ) : (
              <div />
            )}
          </Grid>
          <Grid item key="edm">
            {progress[mo.key].EDM >= 0 ? (
              <CircularProgressWithLabel
                variant={
                  progress[mo.key].status.EDM === "Started" ? "indeterminate" : "determinate"
                }
                color="primary"
                value={progress[mo.key].EDM ? progress[mo.key].EDM : 0}
              />
            ) : (
              <div />
            )}
          </Grid>
          <Grid item key="wc">
            {progress[mo.key].WC >= 0 ? (
              <CircularProgressWithLabel
                variant={progress[mo.key].status.WC === "Started" ? "indeterminate" : "determinate"}
                color="success"
                value={progress[mo.key].WC ? progress[mo.key].WC : 0}
              />
            ) : (
              <div />
            )}
          </Grid>
          <Grid item key="cnc">
            {progress[mo.key].CNC >= 0 ? (
              <CircularProgressWithLabel
                variant={
                  progress[mo.key].status.CNC === "Started" ? "indeterminate" : "determinate"
                }
                color="secondary"
                value={progress[mo.key].CNC ? progress[mo.key].CNC : 0}
              />
            ) : (
              <div />
            )}
          </Grid>
          <Grid item key="mm">
            {progress[mo.key]["MANUAL MILL"] >= 0 ? (
              <CircularProgressWithLabel
                variant={
                  progress[mo.key].status["MANUAL MILL"] === "Started"
                    ? "indeterminate"
                    : "determinate"
                }
                color="error"
                value={progress[mo.key]["MANUAL MILL"] ? progress[mo.key]["MANUAL MILL"] : 0}
              />
            ) : (
              <div />
            )}
          </Grid>
          <Grid item key="lathe">
            {progress[mo.key].LATHE >= 0 ? (
              <CircularProgressWithLabel
                variant={
                  progress[mo.key].status.LATHE === "Started" ? "indeterminate" : "determinate"
                }
                color="error"
                value={progress[mo.key].LATHE ? progress[mo.key].LATHE : 0}
              />
            ) : (
              <div />
            )}
          </Grid>
          {/* <Grid item key="sg">
            <CircularProgressWithLabel variant={progress[mo.key].status==="Started"?"indeterminate":"determinate"} color="info" value={progress[mo.key].SG?progress[mo.key].SG:10}/>
          </Grid>
          <Grid item key="pg">
            <CircularProgressWithLabel variant={progress[mo.key].status==="Started"?"indeterminate":"determinate"} color="inherit" value={progress[mo.key].PG?progress[mo.key].PG:20}/>
          </Grid>
          <Grid item key="edm">
            <CircularProgressWithLabel variant={progress[mo.key].status==="Started"?"indeterminate":"determinate"} color="primary" value={progress[mo.key].EDM?progress[mo.key].EDM:30}/>
          </Grid>
          <Grid item key="wc">
            <CircularProgressWithLabel variant={progress[mo.key].status==="Started"?"indeterminate":"determinate"} color="success" value={progress[mo.key].WC?progress[mo.key].WC:40}/>
          </Grid>
          <Grid item key="cnc">
            <CircularProgressWithLabel variant={progress[mo.key].status==="Started"?"indeterminate":"determinate"} color="warning" value={progress[mo.key].CNC?progress[mo.key].CNC:50}/>
          </Grid>
          <Grid item key="mm">
            <CircularProgressWithLabel variant={progress[mo.key].status==="Started"?"indeterminate":"determinate"} color="error" value={progress[mo.key]["MANUAL MILL"]?progress[mo.key]["MANUAL MILL"]:60}/>
          </Grid>
          <Grid item key="lathe">
            <CircularProgressWithLabel variant={progress[mo.key].status==="Started"?"indeterminate":"determinate"} color="secondary" value={progress[mo.key].LATHE?progress[mo.key].LATHE:70}/> 
          </Grid> */}
        </Grid>
      ),
      et: timesData[mo.key].est || 0,
      elt: timesData[mo.key].elt || 0,
      delivery: new Date(mo.ed),
      details: (
        <div>
          <IconButton
            onClick={() => {
              goTo(`/projects/${mo.projectKey || "N/A"}/${mo.mo || "N/A"}`);
            }}
          >
            <Launch />
          </IconButton>
        </div>
      ),
      project: (
        <div>
          <IconButton
            onClick={() => {
              goTo(`/projects/${mo.projectKey || "N/A"}`);
            }}
          >
            <AccountTree />
          </IconButton>
        </div>
      ),
    })),
    handleFilter,
  };
}
