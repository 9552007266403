import MDBox from "components/MDBox";
import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
import workersPerformancesData from "../data/workersPerformancesData";

export default function WorkersPerformances() {
  const wData = workersPerformancesData();

  return (
    <MDBox mb={3}>
      <ReportsBarChart
        color="info"
        title="Workers' Performances"
        description="This Month Performance"
        date={`updated at ${localStorage.getItem("worker_performances")}`}
        chart={wData}
      />
    </MDBox>
  );
}
