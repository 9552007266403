import useGetWorkersEffectiveHours from "hooks/useGetWorkersEffectiveHours";
import { useSelector } from "react-redux";

export default function workersEffectiveHoursData() {
  const workers = useSelector((state) => state.workers);
  const effectiveHours = useGetWorkersEffectiveHours(workers);
  const eData = {
    labels: [],
    datasets: {
      label: "Effective Hours",
      data: [],
    },
  };

  const hrsArray = Object.keys(effectiveHours).sort(
    (a, b) => effectiveHours[b] - effectiveHours[a]
  );
  hrsArray.slice(0, 5).forEach((key) => {
    if (effectiveHours[key] > 0) {
      eData.labels.push(workers.filter((worker) => worker.key === key)[0].name);
      eData.datasets.data.push(effectiveHours[key]);
    }
  });

  return eData;
}
