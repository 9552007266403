/* eslint-disable import/no-unresolved */
/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import useGetAvailableHours from "hooks/useGetAvailableHours";

/**
 *
 * @returns {Object} of all available hours
 */

export default function data() {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const month = new Date(Date.now()).getMonth();
  const { total, reserved } = useGetAvailableHours();

  const SG = [
    [
      {
        labels: ["Available hours", "Reserved Hours"],
        datasets: {
          label: `SG - ${months[month]}`,
          backgroundColors: ["info", "primary"],
          data: [total[1].SG - reserved[1].SG, reserved[1].SG],
        },
      },
      {
        labels: ["Available hours", "Reserved Hours"],
        datasets: {
          label: `SG - ${months[month]}`,
          backgroundColors: ["info", "primary"],
          data: [
            total.fromToday.SG - reserved.fromToday.SG > 0
              ? total.fromToday.SG - reserved.fromToday.SG
              : 0,
            reserved.fromToday.SG,
          ],
        },
      },
    ],
    {
      labels: ["Available hours", "Reserved Hours"],
      datasets: {
        label: `SG - ${months[month + 1]}`,
        backgroundColors: ["info", "primary"],
        data: [total[2].SG - reserved[2].SG, reserved[2].SG],
      },
    },
    {
      labels: ["Available hours", "Reserved Hours"],
      datasets: {
        label: `SG - ${months[month + 2]}`,
        backgroundColors: ["info", "primary"],
        data: [total[3].SG - reserved[3].SG, reserved[3].SG],
      },
    },
  ];
  const PG = [
    [
      {
        labels: ["Available hours", "Reserved Hours"],
        datasets: {
          label: `PG - ${months[month]}`,
          backgroundColors: ["info", "primary"],
          data: [total[1].PG - reserved[1].PG, reserved[1].PG],
        },
      },
      {
        labels: ["Available hours", "Reserved Hours"],
        datasets: {
          label: `PG - ${months[month]}`,
          backgroundColors: ["info", "primary"],
          data: [
            total.fromToday.PG - reserved.fromToday.PG > 0
              ? total.fromToday.PG - reserved.fromToday.PG
              : 0,
            reserved.fromToday.PG,
          ],
        },
      },
    ],
    {
      labels: ["Available hours", "Reserved Hours"],
      datasets: {
        label: `PG - ${months[month + 1]}`,
        backgroundColors: ["info", "primary"],
        data: [total[2].PG - reserved[2].PG, reserved[2].PG],
      },
    },
    {
      labels: ["Available hours", "Reserved Hours"],
      datasets: {
        label: `PG - ${months[month + 2]}`,
        backgroundColors: ["info", "primary"],
        data: [total[3].PG - reserved[3].PG, reserved[3].PG],
      },
    },
  ];
  const WC = [
    [
      {
        labels: ["Available hours", "Reserved Hours"],
        datasets: {
          label: `WC - ${months[month]}`,
          backgroundColors: ["info", "primary"],
          data: [total[1].WC - reserved[1].WC, reserved[1].WC],
        },
      },
      {
        labels: ["Available hours", "Reserved Hours"],
        datasets: {
          label: `WC - ${months[month]}`,
          backgroundColors: ["info", "primary"],
          data: [
            total.fromToday.WC - reserved.fromToday.WC > 0
              ? total.fromToday.WC - reserved.fromToday.WC
              : 0,
            reserved.fromToday.WC,
          ],
        },
      },
    ],
    {
      labels: ["Available hours", "Reserved Hours"],
      datasets: {
        label: `WC - ${months[month + 1]}`,
        backgroundColors: ["info", "primary"],
        data: [total[2].WC - reserved[2].WC, reserved[2].WC],
      },
    },
    {
      labels: ["Available hours", "Reserved Hours"],
      datasets: {
        label: `WC - ${months[month + 2]}`,
        backgroundColors: ["info", "primary"],
        data: [total[3].WC - reserved[3].WC, reserved[3].WC],
      },
    },
  ];
  const EDM = [
    [
      {
        labels: ["Available hours", "Reserved Hours"],
        datasets: {
          label: `EDM - ${months[month]}`,
          backgroundColors: ["info", "primary"],
          data: [total[1].EDM - reserved[1].EDM, reserved[1].EDM],
        },
      },
      {
        labels: ["Available hours", "Reserved Hours"],
        datasets: {
          label: `EDM - ${months[month]}`,
          backgroundColors: ["info", "primary"],
          data: [
            total.fromToday.EDM - reserved.fromToday.EDM > 0
              ? total.fromToday.EDM - reserved.fromToday.EDM
              : 0,
            reserved.fromToday.EDM,
          ],
        },
      },
    ],
    {
      labels: ["Available hours", "Reserved Hours"],
      datasets: {
        label: `EDM - ${months[month + 1]}`,
        backgroundColors: ["info", "primary"],
        data: [total[2].EDM - reserved[2].EDM, reserved[2].EDM],
      },
    },
    {
      labels: ["Available hours", "Reserved Hours"],
      datasets: {
        label: `EDM - ${months[month + 2]}`,
        backgroundColors: ["info", "primary"],
        data: [total[3].EDM - reserved[3].EDM, reserved[3].EDM],
      },
    },
  ];
  const CNC = [
    [
      {
        labels: ["Available hours", "Reserved Hours"],
        datasets: {
          label: `CNC - ${months[month]}`,
          backgroundColors: ["info", "primary"],
          data: [total[1].CNC - reserved[1].CNC, reserved[1].CNC],
        },
      },
      {
        labels: ["Available hours", "Reserved Hours"],
        datasets: {
          label: `CNC - ${months[month]}`,
          backgroundColors: ["info", "primary"],
          data: [
            total.fromToday.CNC - reserved.fromToday.CNC > 0
              ? total.fromToday.CNC - reserved.fromToday.CNC
              : 0,
            reserved.fromToday.CNC,
          ],
        },
      },
    ],
    {
      labels: ["Available hours", "Reserved Hours"],
      datasets: {
        label: `CNC - ${months[month + 1]}`,
        backgroundColors: ["info", "primary"],
        data: [total[2].CNC - reserved[2].CNC, reserved[2].CNC],
      },
    },
    {
      labels: ["Available hours", "Reserved Hours"],
      datasets: {
        label: `CNC - ${months[month + 2]}`,
        backgroundColors: ["info", "primary"],
        data: [total[3].CNC - reserved[3].CNC, reserved[3].CNC],
      },
    },
  ];

  return {
    SG,
    PG,
    WC,
    EDM,
    CNC,
  };
}
