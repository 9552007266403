/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/* eslint-disable no-unused-vars */

import { Edit, Delete, ViewAgenda } from "@mui/icons-material";
import { Grid, IconButton } from "@mui/material";

import { useMemo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDBadge from "components/MDBadge";

// Images
import team2 from "assets/images/team-2.jpg";
// import team3 from "assets/images/team-3.jpg";
// mport team4 from "assets/images/team-4.jpg";

import useGetWorkersPerformances from "hooks/useGetWorkersPerformances";
import useGetWorkersEffectiveHours from "hooks/useGetWorkersEffectiveHours";
import { getWorkers, getPPs } from "../../../redux/slices/workersSlice";

export default function data() {
  const workers = useSelector((state) => state.workers);
  const performances = useGetWorkersPerformances(workers);
  const effectiveHours = useGetWorkersEffectiveHours(workers);
  const dispatch = useDispatch();

  useMemo(() => {
    dispatch(getPPs(workers));
  }, [workers.length]);

  // const Job = ({ title, description }) => (
  //   <MDBox lineHeight={1} textAlign="left">
  //     <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
  //       {title}
  //     </MDTypography>
  //     <MDTypography variant="caption">{description}</MDTypography>
  //   </MDBox>
  // );

  return {
    columns: [
      { Header: "ID", accessor: "id", align: "left" },
      { Header: "image", accessor: "image", align: "left" },
      { Header: "name", accessor: "name", align: "left" },
      { Header: "performance", accessor: "performance", align: "left" },
      { Header: "Effective Hours", accessor: "eh", align: "left" },
      { Header: "department", accessor: "dep", align: "left" },
      { Header: "assigned work", accessor: "work", align: "left" },
      { Header: "status", accessor: "status", align: "center" },
      { Header: "working", accessor: "working", align: "center" },
      // { Header: "customizene", accessor: "cust", align: "center" },
    ],

    rows: workers.map((worker) => ({
      id: worker.id,
      image: <MDAvatar src={worker.pp || "#"} />,
      name: worker.name,
      performance: `${performances[worker.id]} %`,
      eh: Number(effectiveHours[worker.id]).toFixed(2),
      dep: worker.department,
      work: worker.assigned_work.join(", "),
      status: (
        <MDBox ml={-1}>
          <MDBadge
            badgeContent={worker.occupied ? "Occupied" : "Available"}
            color={worker.occupied ? "success" : "info"}
            variant="gradient"
            size="sm"
          />
        </MDBox>
      ),
      working: (
        <MDBox ml={-1}>
          <MDBadge
            badgeContent={worker.working ? "IN" : "OUT"}
            color="success"
            variant="gradient"
            size="sm"
          />
        </MDBox>
      ),
      // cust:(
      //   <Grid container spacing={2}>
      //     <Grid item xs={4} key={0}>
      //       <IconButton>
      //         <Delete/>
      //       </IconButton>
      //     </Grid>
      //     <Grid item xs={4} key={1}>
      //       <IconButton>
      //         <Edit/>
      //       </IconButton>
      //     </Grid>
      //     <Grid item xs={4} key={2}>
      //       <IconButton>
      //         <ViewAgenda/>
      //       </IconButton>
      //     </Grid>
      //   </Grid>
      // )
    })),
  };
}
