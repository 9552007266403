import { Image, StyleSheet,Text,View } from "@react-pdf/renderer";
import PropTypes from "prop-types"

const styles = StyleSheet.create({
    container:{
        position: 'absolute',
        width: '548px',
        height: '132px',
        left: '25px',
        top: '11px'
    },
    name: {
        position: 'absolute',
        width: '310px',
        height: '32px',
        left: '25px',
        top: '18px',
        fontFamily:'Times-Bold',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '12px',
        textAlign: 'center',
        color: '#000000'
    },
    qr:{
        position: 'absolute',
        width: '20%',
        height: 'auto',
        left: '434px',
        top: '11px'
    }
})


export default function Header({partKey}){
    return(
        <View style={styles.container}>
            <Text style={styles.name}>LANKA PRECISION ENGINEERING PRIVATE LIMITED PRODUCTION M/O RECORD</Text>
            <Image style={styles.qr} src={`https://api.qrserver.com/v1/create-qr-code/?size=150x150&data=${partKey}`}/>
        </View>
    );
}

Header.propTypes={
    partKey:PropTypes.string.isRequired
}