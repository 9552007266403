/* eslint-disable camelcase */

import { useSelector } from "react-redux";

export default function workingHourData() {
  const workersPerformances = useSelector((state) => state.workersPerformances);
  const thisMonth = new Date(Date.now()).getMonth();
  const thisYear = new Date(Date.now()).getFullYear();
  let lastMYear = thisYear;
  let lastMonth = thisMonth - 1;
  if (thisMonth === 0) {
    lastMonth = 11;
    lastMYear -= 1;
  }

  const recordedThisMonth = workersPerformances.filter((p) => {
    const { date } = p;
    const recordedMonth = new Date(date).getMonth();
    const recordedYear = new Date(date).getFullYear();
    return recordedMonth === thisMonth && thisYear === recordedYear;
  });

  const recordedLastMonth = workersPerformances.filter((p) => {
    const { date } = p;
    const recordedMonth = new Date(date).getMonth();
    const recordedYear = new Date(date).getFullYear();
    return recordedMonth === lastMonth && recordedYear === lastMYear;
  });

  const wHThisMonth =
    recordedThisMonth.reduce((n, { consumed_time }) => n + consumed_time, 0).toFixed(2) || 1;
  const wHLastMonth = recordedLastMonth
    .reduce((n, { consumed_time }) => n + consumed_time, 0)
    .toFixed(2);

  return {
    count: wHThisMonth,
    percentage: (((wHThisMonth - wHLastMonth) / wHThisMonth || 1) * 100).toFixed(2),
  };
}
