export default function getTimes(mo) {
  const { jobs } = mo;
  let est = 0;
  let elt = 0;

  jobs.forEach((job) => {
    est += parseFloat(job.estimated_time);
    if (job.progress) {
      job.progress.forEach((progress) => {
        elt += progress.time / 3600000;
      });
    }
  });

  return {
    est,
    elt: elt.toFixed(2),
  };
}
