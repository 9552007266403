import { Box, CircularProgress, Typography } from "@mui/material";
import PropTypes from 'prop-types';

export default function CircularProgressWithLabel(props) {
    const {value,color} = props;
    return (
      <Box sx={{ position: 'relative', display: 'inline-flex' }}>
        <CircularProgress size={45} variant="determinate" {...props} />
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography variant="subtitle" component="div" sx={{color: `${color}.main`}}>
            {Math.round(value)}%
          </Typography>
        </Box>
      </Box>
    );
  }
  
  CircularProgressWithLabel.propTypes = {
    /**
     * The value of the progress indicator for the determinate variant.
     * Value between 0 and 100.
     * @default 0
     */
    value: PropTypes.number.isRequired,
    color: PropTypes.string.isRequired,
  };