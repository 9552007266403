import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import PropTypes from "prop-types";
import CircularLoading from './CircularLoading';

function AlertDialog({open,loading,k,handleClose,handleAction,title,context}){
  return(
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {context}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          {loading?<CircularLoading/>:
            <Button onClick={()=>{
              handleAction(k)
              }} autoFocus>
              Delete
            </Button>
          }
        </DialogActions>
      </Dialog>
    </div>
  )
};


AlertDialog.propTypes={
  open:PropTypes.bool.isRequired,
  loading:PropTypes.bool.isRequired,
  k:PropTypes.string.isRequired,
  handleClose:PropTypes.func.isRequired,
  handleAction:PropTypes.func.isRequired,
  title:PropTypes.string.isRequired,
  context:PropTypes.string.isRequired
}

export default AlertDialog;