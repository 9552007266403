// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

import Select from "react-select";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
// import FormControl from "@mui/material/FormControl"

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import { useSelector } from "react-redux";
import { useState } from "react";
import MDEditor from "components/MDEditor";
import MDDatePicker from "components/MDDatePicker";

// Form
import { useForm } from "react-hook-form";
import { Form } from "semantic-ui-react";

// Hooks
import { useAddNewProject } from "hooks/useProjects";

function NewProject({ handleStatus }) {
  const [customer, setCustomer] = useState("");
  const [drawingsCount, setDrawingsCount] = useState(0);
  const [eD, setED] = useState(0);
  const [iPO, setIPO] = useState("");
  const [cPO, setCPO] = useState("");
  const [issued, setIssued] = useState("");
  const [price, setPrice] = useState("");
  const [remarks, setRemarks] = useState("");
  const customers = useSelector((state) => state.customers);
  const addNewProject = useAddNewProject();
  const { handleSubmit } = useForm();

  const clearSelection = () => {
    setDrawingsCount(0);
    setED(0);
    setIPO("");
    setCPO("");
    setIssued("");
    setPrice("");
    setRemarks("");
  };

  const handleCustomerChange = (e) => {
    setCustomer(e.label);
  };

  const handleDrawingsCountChange = (e) => setDrawingsCount(parseInt(e.target.value, 10));
  const handleEDChange = (e) => setED(e[0]);
  const handleIPOChange = (e) => setIPO(e.target.value);
  const handleCPOChange = (e) => setCPO(e.target.value);
  const handleIssuedChange = (e) => setIssued(e.target.value);
  const handlePriceChange = (e) => setPrice(e.target.value);
  const handleRemarksChange = (e) => setRemarks(e);
  const handleSaveProject = async () => {
    try {
      await addNewProject({
        id: Date.now(),
        customer,
        parts_count: drawingsCount,
        estimated_delivery: eD,
        ipo: iPO,
        cpo: cPO,
        issued_by: issued,
        price,
        remarks,
        completed: false,
      });

      setCustomer("");
      setDrawingsCount(0);
      handleStatus("Project Added!", "New Project has been added.", "success");
      clearSelection();
    } catch (error) {
      if (error.toString().includes("invalid data")) {
        handleStatus("Project did not Added!", `All Fields are Required!`, "error");
      } else {
        handleStatus("Server Error!", `Contact Admin!`, "error");
      }
    }
  };

  return (
    <Form onSubmit={handleSubmit(handleSaveProject)}>
      <MDBox pt={6} pb={3}>
        <Card>
          <MDBox
            mx={2}
            mt={-3}
            py={3}
            px={2}
            variant="gradient"
            bgColor="info"
            borderRadius="lg"
            coloredShadow="info"
          >
            <MDTypography variant="h6" color="white">
              Add New Project
            </MDTypography>
          </MDBox>
          <MDBox mx={2} mt={1} py={3} px={2}>
            <Grid container spacing={12}>
              <Grid item xs={8}>
                <MDBox pt={1} pb={1}>
                  <Grid container spacing={2}>
                    <Grid item xs={6} key={0}>
                      <Card>
                        <Select
                          onChange={handleCustomerChange}
                          options={customers.map((cust) => ({
                            value: cust.key,
                            label: cust.name,
                          }))}
                        />
                      </Card>
                    </Grid>
                    <Grid item xs={6} key={1}>
                      <MDInput
                        onChange={handleIPOChange}
                        type="text"
                        value={iPO}
                        label="IPO"
                        required
                      />
                    </Grid>
                  </Grid>
                </MDBox>
                <MDBox pt={1} pb={1}>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <MDInput
                        onChange={handleDrawingsCountChange}
                        type="number"
                        value={drawingsCount}
                        label="Drawings Count"
                        required
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <MDInput
                        type="text"
                        value={cPO}
                        label="Customer PO"
                        onChange={handleCPOChange}
                        required
                      />
                    </Grid>
                  </Grid>
                </MDBox>
                <MDBox pt={1} pb={1}>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <MDDatePicker
                        input={{ placeholder: "Estimated Delivery" }}
                        onChange={handleEDChange}
                        required
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <MDInput
                        type="text"
                        value={issued}
                        label="Issued By"
                        onChange={handleIssuedChange}
                        required
                      />
                    </Grid>
                  </Grid>
                </MDBox>
              </Grid>
              <Grid item xs={4}>
                <MDInput
                  type="number"
                  label="Price"
                  value={price}
                  onChange={handlePriceChange}
                  required
                />
              </Grid>
              <Grid item xs={4}>
                <MDEditor
                  placeholder="Remarks"
                  value={remarks}
                  theme="snow"
                  onChange={handleRemarksChange}
                />
              </Grid>
            </Grid>
            <Grid container spacing={{ xs: 2, md: 2 }} columns={{ xs: 4, sm: 8, md: 12 }}>
              <Grid item xs={2} sm={4} md={4} key={2}>
                <MDBox mx={2} mt={1} py={3} px={2}>
                  <MDButton color="info" type="submit">
                    Add
                  </MDButton>
                </MDBox>
              </Grid>
            </Grid>
          </MDBox>
        </Card>
      </MDBox>
    </Form>
  );
}

/* eslint-disable react/forbid-prop-types */

NewProject.propTypes = {
  handleStatus: PropTypes.func.isRequired,
};

export default NewProject;
