import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getDocs, collection } from "firebase/firestore";
import { getDownloadURL, ref } from "firebase/storage";
import { db, storage } from "../../firebase";

export const workersSlice = createSlice({
  name: "workers",
  initialState: [],
  reducers: {},
  /* eslint-disable no-use-before-define */
  /* eslint-disable no-unused-vars */
  /* eslint-disable no-param-reassign */
  /* eslint-disable prefer-destructuring */
  /* eslint-disable camelcase */
  extraReducers(builder) {
    builder.addCase(getWorkers.fulfilled, (state, action) => {
      localStorage.setItem("workers", new Date(Date.now()).toTimeString().split(" ")[0]);
      const data = action.payload.data;
      return data;
    });
    builder.addCase(getPPs.fulfilled, (state, action) => {
      localStorage.setItem("workers", new Date(Date.now()).toTimeString().split(" ")[0]);
      const data = action.payload.data;
      return data;
    });
  },
});

export const getWorkers = createAsyncThunk("workers/getWorkers", async () => {
  const data = await getDocs(collection(db, "workers")).then((querySnapshot) => {
    const newData = querySnapshot.docs.map((doc) => {
      const originalData = doc.data();
      originalData.key = doc.id;

      try {
        originalData.createdAt = originalData.createdAt.seconds * 1000;
        originalData.updatedAt = originalData.updatedAt.seconds * 1000;
      } catch (e) {
        originalData.createdAt = 1673254226;
        originalData.updatedAt = 1673254226;
      }

      return { ...originalData };
    });
    return newData;
  });
  return { data };
});

export const getPPs = createAsyncThunk("workers/getPPs", async (workers) => {
  const data = await Promise.all(
    workers.map(async (profile) => {
      const dRef = ref(storage, `profiles/${profile.id}.jpeg`);
      let url = "#";
      try {
        url = await getDownloadURL(dRef);
      } catch (e) {
        url = "#";
      }
      return { ...profile, ...{ pp: url } };
    })
  );
  return { data };
});

export default workersSlice.reducer;
