// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import { useForm } from "react-hook-form";
import { Form } from "semantic-ui-react";
import Select from "react-select";
import MDDropzone from "components/MDDropzone";
import uploadPP from "utils/uploadPP";
import { useState } from "react";
import { useAddNewWorker } from "hooks/useWorkers";

function NewWorker({ handleStatus }) {
  const [wName, setWName] = useState("");
  const [wNumber, setWNumber] = useState(0);
  const [department, setDepartment] = useState("");
  const addNewWorker = useAddNewWorker();
  const { handleSubmit } = useForm();

  const handleWNameChange = (e) => setWName(e.target.value);
  const handleWNumberChange = (e) => setWNumber(e.target.value);
  const handleDepChange = (e) => setDepartment(e.value);
  const handleSaveWorker = async () => {
    try {
      await addNewWorker({ name: wName, id: wNumber, occupied: false, working: true, department });
      setWName("");
      setWNumber(0);
      handleStatus("Worker Added!", "New Worker has been added.", "success");
    } catch (error) {
      if (error.toString().includes("invalid data")) {
        handleStatus("Worker is not Added!", "Check all fields.", "error");
      } else {
        console.warn(error);
        handleStatus("Server Error!", "Contact Admin.", "error");
      }
    }
  };

  const departments = [
    { label: "PG", value: "PG" },
    { label: "SG", value: "SG" },
    { label: "EDM", value: "EDM" },
    { label: "CNC", value: "CNC" },
    { label: "LATHE", value: "LATHE" },
    { label: "WC", value: "WC" },
    { label: "MILLING", value: "MILLING" },
    { label: "TOOLING", value: "TOOLING" },
  ];
  return (
    <Form onSubmit={handleSubmit(handleSaveWorker)}>
      <MDBox pt={6} pb={3}>
        <Card>
          <MDBox
            mx={2}
            mt={-3}
            py={3}
            px={2}
            variant="gradient"
            bgColor="info"
            borderRadius="lg"
            coloredShadow="info"
          >
            <MDTypography variant="h6" color="white">
              Add New Worker
            </MDTypography>
          </MDBox>
          <MDBox mx={2} mt={1} py={3} px={2}>
            <Grid container spacing={2}>
              <Grid item xs={4} key={0}>
                <MDInput onChange={handleWNameChange} label="Worker's Name" required />
              </Grid>
              <Grid item xs={4} key={1}>
                <MDInput
                  type="number"
                  label="Worker's Number"
                  onChange={handleWNumberChange}
                  required
                />
              </Grid>
              <Grid item xs={4} key={2}>
                <Select required options={departments} onChange={handleDepChange} />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={2} key={0}>
                <MDTypography>Upload a clear image:</MDTypography>
              </Grid>
              <Grid item xs={2} key={1}>
                <MDDropzone
                  options={{
                    url: async (files) => {
                      const url = await uploadPP(files[0], wNumber);
                      return url;
                    },
                    maxFiles: 1,
                    acceptedFiles: "image/jpeg",
                    addRemoveLinks: true,
                  }}
                />
              </Grid>
              <Grid item xs={8} key={2} />
            </Grid>
            <MDBox mx={2} mt={1} py={3} px={2}>
              <MDButton color="info" type="submit">
                Add
              </MDButton>
            </MDBox>
          </MDBox>
        </Card>
      </MDBox>
    </Form>
  );
}

NewWorker.propTypes = {
  handleStatus: PropTypes.func.isRequired,
};

export default NewWorker;
