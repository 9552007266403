import { ref,uploadBytes } from "firebase/storage";
import { storage } from "../firebase";

/* eslint-disable camelcase */

const uploadPP =async (file,workerId)=>{
    const fileRef = ref(storage,`profiles/${workerId}.jpeg`);
    await uploadBytes(fileRef,file);
    return "post"
}

export default uploadPP