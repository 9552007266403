import { StyleSheet, Text, View } from "@react-pdf/renderer";
import PropTypes from "prop-types";

const styles = StyleSheet.create({
  container: {
    position: "absolute",
    width: "548px",
    height: "132px",
    left: "25px",
    top: "11px",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "10px",
    textAlign: "left",
    color: "#000000",
  },
  mo: {
    position: "absolute",
    width: "200px",
    height: "16px",
    left: "0px",
    top: "77px",
    fontFamily: "Times-Bold",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "12px",
    textAlign: "left",
    color: "#000000",
  },
  customer: {
    position: "absolute",
    width: "200px",
    height: "16px",
    left: "0px",
    top: "93px",
    fontFamily: "Times-Bold",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "12px",
    textAlign: "left",
    color: "#000000",
  },
  ipo: {
    position: "absolute",
    width: "200px",
    height: "16px",
    left: "0px",
    top: "108px",
    fontFamily: "Times-Bold",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "12px",
    textAlign: "left",
    color: "#000000",
  },
  received: {
    position: "absolute",
    width: "200px",
    height: "16px",
    left: "0px",
    top: "124px",
    fontFamily: "Times-Bold",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "12px",
    textAlign: "left",
    color: "#000000",
  },
  cpo: {
    position: "absolute",
    width: "200px",
    height: "16px",
    left: "0px",
    top: "139px",
    fontFamily: "Times-Bold",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "12px",
    textAlign: "left",
    color: "#000000",
  },
  item: {
    position: "absolute",
    width: "200px",
    height: "16px",
    left: "205px",
    top: "74px",
    fontFamily: "Times-Bold",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "12px",
    textAlign: "left",
    color: "#000000",
  },
  delivery: {
    position: "absolute",
    width: "200px",
    height: "16px",
    left: "205px",
    top: "90px",
    fontFamily: "Times-Bold",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "12px",
    textAlign: "left",
    color: "#000000",
  },
  quantity: {
    position: "absolute",
    width: "200px",
    height: "16px",
    left: "205px",
    top: "105px",
    fontFamily: "Times-Bold",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "12px",
    textAlign: "left",
    color: "#000000",
  },
  material: {
    position: "absolute",
    width: "200px",
    height: "16px",
    left: "205px",
    top: "121px",
    fontFamily: "Times-Bold",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "12px",
    textAlign: "left",
    color: "#000000",
  },
});

export default function ProjectData({
  mo,
  customer,
  ipo,
  received,
  cpo,
  item,
  delivery,
  quantity,
  type,
  material,
}) {
  return (
    <View style={styles.container}>
      <Text style={styles.mo}>M/O NO. : {mo}</Text>
      <Text style={styles.customer}>CUSTOMER : {customer}</Text>
      <Text style={styles.ipo}>IPO NO. : {ipo}</Text>
      <Text style={styles.received}>RECEIVED ON: {received}</Text>
      <Text style={styles.cpo}>CUS.PO NO. : {cpo}</Text>
      <Text style={styles.item}>ITEM NAME : {item}</Text>
      <Text style={styles.delivery}>DELIVERY DATE: {delivery}</Text>
      <Text style={styles.quantity}>
        QUANTITY : {quantity > 1 ? `${quantity} ${type}s` : `${quantity} ${type}`}
      </Text>
      <Text style={styles.material}>MATERIAL : {material}</Text>
    </View>
  );
}

ProjectData.propTypes = {
  mo: PropTypes.string.isRequired,
  customer: PropTypes.string.isRequired,
  ipo: PropTypes.string.isRequired,
  received: PropTypes.string.isRequired,
  cpo: PropTypes.string.isRequired,
  item: PropTypes.string.isRequired,
  delivery: PropTypes.string.isRequired,
  quantity: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
  material: PropTypes.string.isRequired,
};
