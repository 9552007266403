/* eslint-disable no-unused-vars */
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth, connectAuthEmulator } from "firebase/auth";
import {
  getFirestore,
  connectFirestoreEmulator,
  enableIndexedDbPersistence,
  loadBundle,
  namedQuery,
  query,
  getDocs,
  initializeFirestore,
} from "firebase/firestore";
import { getStorage, connectStorageEmulator } from "firebase/storage";
import { getPerformance } from "firebase/performance";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyA9-X41Fao3BSb7zjpwQgTFhgSGucEX3zA",
  authDomain: "lankape-40341.firebaseapp.com",
  projectId: "lankape-40341",
  storageBucket: "lankape-40341.appspot.com",
  messagingSenderId: "320025506297",
  appId: "1:320025506297:web:aa518d50da45a9af668d28",
  measurementId: "G-WSVLTYYKZQ",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = initializeFirestore(app, { cacheSizeBytes: 5 * 10 ** 6 });
const storage = getStorage(app);
const perf = getPerformance(app);

// if (window.location.hostname === "localhost") {
//   connectFirestoreEmulator(db, "localhost", 8080);
//   connectAuthEmulator(auth, "http://localhost:9099/", { disableWarnings: true });
//   connectStorageEmulator(storage, "localhost", 9199);
// }

// enableIndexedDbPersistence(db).catch((err) => {
//   if (err.code === "failed-precondition") {
//     // Multiple tabs open, persistence can only be enabled
//     // in one tab at a a time.
//     // ...
//   } else if (err.code === "unimplemented") {
//     // The current browser does not support all of the
//     // features required to enable persistence
//     // ...
//   }
// });

export { db, storage, auth };
